const getNativeReducer = state => state.native;

const selectors = {
  isTouchIdCanBeUsed: store => getNativeReducer(store).isTouchIdCanBeUsed,
  isBiometricsFlowEnabled: store =>
    getNativeReducer(store).isBiometricsFlowEnabled,
  isBiometricsEnrolled: store => getNativeReducer(store).isBiometricsEnrolled,
  isCasinoInSportEnabled: store =>
    getNativeReducer(store).isCasinoInSportEnabled
};

export default { ...selectors };
