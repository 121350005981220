import { useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ControlCenterSelectors,
  UserContextSelectors
} from "../../../redux/selectors";
import { PageContextUtils } from "../../../utils";
import { AppFeatures } from "../../../constants/featureFlags";
import { RegulationTypeIds } from "../../../constants";
import { Brand } from "../../../configurations";
import { BrandUtils } from "../../../utils/brandUtils";
import { ControlCenterActionCreators } from "../../../redux/action-creators";
import { useFeature } from "../../../hooks/useFeature";

const ComBrands = [Brand.Sport_Com, Brand.Sport_Spoker, Brand.Sport_Scasino];

export const useControlCenterState = () => {
  const enabled = useSelector(ControlCenterSelectors.enabled);
  const isAuthenticated = useSelector(UserContextSelectors.isAuthenticated);
  const brandId = useSelector(UserContextSelectors.brandId);
  const isComBrand = useMemo(
    () => ComBrands.some(brand => brand === brandId),
    [brandId]
  );
  const dispatch = useDispatch();

  const setControlCenterEnabled = useCallback(
    isEnabled => dispatch(ControlCenterActionCreators.setEnabled(isEnabled)),
    [dispatch]
  );

  const isControlCenterActive = useFeature(
    AppFeatures.CONTROL_CENTER,
    AppFeatures.SAFEZONE_GENERIC
  );

  useEffect(() => {
    if (isAuthenticated) {
      const getControlCenterEnabled = () => {
        let isActiveForCurrentRegulationType = true;

        const { regulationTypeId } = PageContextUtils.getRegulationData();

        if (BrandUtils.isPokerClient()) {
          isActiveForCurrentRegulationType =
            regulationTypeId === RegulationTypeIds.UK;
        }

        if (isComBrand && regulationTypeId === RegulationTypeIds.Gibraltar) {
          isActiveForCurrentRegulationType = false;
        }

        setControlCenterEnabled(
          isControlCenterActive && isActiveForCurrentRegulationType
        );
      };

      getControlCenterEnabled();
    }
  }, [
    isAuthenticated,
    isComBrand,
    isControlCenterActive,
    setControlCenterEnabled
  ]);

  return enabled;
};
