import { createSelector } from "reselect";
import { PageContextUtils } from "../../utils";
import { ClientProviderEnum } from "../../constants";

const flattenCategories = (items, result) => {
  result = result || [];
  if (!items) return result;

  const { name } = PageContextUtils.getClientProviderData();

  items.forEach(item => {
    if (item.items?.length > 0) {
      result.push(item);
      result.push(...flattenCategories(item.items, result));
    } else {
      result.push(item);
    }
  });

  if (name === ClientProviderEnum.Spectate) {
    return result?.filter(elem => elem.active);
  }

  return result;
};

const selectors = {
  loyaltySectionCategories: store =>
    getMenuReducer(store).loyaltySectionCategories,
  favouriteSports: store => getMenuReducer(store).favouriteSports,
  favouriteSportsRunningInsideInnerMenu: store =>
    getMenuReducer(store).favouriteSportsRunningInsideInnerMenu,
  favouriteSportsSelectable: store =>
    getMenuReducer(store).favouriteSportsSelectable,
  homeSectionCategories: store => getMenuReducer(store).homeSectionCategories,
  innerMenuIgnoreClass: store => getMenuReducer(store).innerMenuIgnoreClass,
  isMenuClientFailed: store => !!getMenuReducer(store).clientFailed,
  drawersRendering: store => getMenuReducer(store).drawersRendering,
  drawersLoaded: store => getMenuReducer(store).drawersLoaded,
  selectedItem: store => getMenuReducer(store).selectedItem,
  quickLinksInnerMenuItem: store =>
    getMenuReducer(store).quickLinksInnerMenuItem,
  sportCategories: store => getMenuReducer(store).sportCategories,
  hamburgerMenuVisible: store => getMenuReducer(store).hamburgerMenuVisible,
  languageMenuVisible: store => getMenuReducer(store).languageMenuVisible,
  oddsMenuVisible: store => getMenuReducer(store).oddsMenuVisible,
  quickLinksInnerMenuVisible: store =>
    getMenuReducer(store).quickLinksInnerMenuVisible,
  appearanceMenuVisible: store => getMenuReducer(store).appearanceMenuVisible,
  numberOfDisplayedItems: store => getMenuReducer(store).numberOfDisplayedItems,
  menuItems: store => getMenuReducer(store).menuItems
};

const getMenuReducer = state => state.menu;

const categories = createSelector(
  [selectors.homeSectionCategories, selectors.sportCategories],
  (homeSectionCategories, sportCategories) => [
    ...(homeSectionCategories || []),
    ...(sportCategories || [])
  ]
);

const flattenedCategories = createSelector(
  [categories, selectors.favouriteSports],
  (categories, favouriteSports) => [
    ...(favouriteSports || []),
    ...(flattenCategories(categories) || [])
  ]
);

const quickLinkDrawers = createSelector([selectors.menuItems], menuItems =>
  menuItems?.filter(menuItem => menuItem?.props?.item?.items?.length)
);

export default {
  ...selectors,
  categories,
  flattenedCategories,
  quickLinkDrawers
};
