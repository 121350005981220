import { Channel, ROOT, ClientProviderEnum, Topic } from "../constants";
import { MenuSelectors, UserContextSelectors } from "../redux/selectors";
import { MessageBrokerUtils, PageContextUtils, UrlUtils } from "../utils";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HighlightingMatch } from "../components/Menu";
import { MenuActionCreators } from "../redux/action-creators";

const findMatch = (items, uri) => {
  let index = 0;
  const matches = [];

  if (!items?.length > 0) return null;
  const urlWithoutLang = UrlUtils.getURLWithoutLang(uri);

  const rootSEOUrlWithoutLang = UrlUtils.getSEOUrlWithoutLang(ROOT);
  const seoURLWithoutLang = UrlUtils.getSEOUrlWithoutLang(urlWithoutLang);

  if (seoURLWithoutLang === rootSEOUrlWithoutLang) {
    const homeItem = items?.find(
      item => UrlUtils.getSEOUrlWithoutLang(item.URL) === seoURLWithoutLang
    );

    matches.push(
      new HighlightingMatch({
        item: homeItem,
        order: ++index,
        length: homeItem?.URL.length || 0
      })
    );
  } else {
    items?.forEach(item => {
      if (!item.URL || item.hidden) return null;
      const itemUrl = UrlUtils.getURLWithoutLang(item.URL);

      if (itemUrl !== ROOT && urlWithoutLang.startsWith(itemUrl)) {
        matches.push(
          new HighlightingMatch({
            order: ++index,
            item,
            length: itemUrl.length
          })
        );
        return;
      }

      const splittedUrl = itemUrl.split("/").filter(elem => !!elem);
      const urlWithoutAlias = `/${(splittedUrl.length > 1
        ? splittedUrl.slice(0, splittedUrl.length - 1)
        : splittedUrl
      ).join("/")}/`;

      if (uri.startsWith(urlWithoutAlias)) {
        matches.push(
          new HighlightingMatch({
            order: ++index,
            item,
            length: urlWithoutAlias.length
          })
        );
      }
    });
  }

  const matchesSortedByLength = matches.sort((a, b) => b.length - a.length);
  const firstMatch =
    (matchesSortedByLength.length > 0 && matchesSortedByLength[0]) || null;
  if (!firstMatch) return null;

  return matchesSortedByLength.filter(
    match => match.length === firstMatch.length
  )[0]?.item;
};

export const useSpectateMenuSelection = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(UserContextSelectors.isMobile);
  const flattenedCategories = useSelector(MenuSelectors.flattenedCategories);
  const favouriteSports = useSelector(MenuSelectors.favouriteSports);
  const { name } = PageContextUtils.getClientProviderData();

  const selectItem = useCallback(
    uri => {
      const selectedItem = findMatch(flattenedCategories, uri);
      if (selectedItem) {
        dispatch(MenuActionCreators.select(selectedItem));
      }
    },
    [flattenedCategories, dispatch]
  );

  useEffect(() => {
    if (name !== ClientProviderEnum.Kambi)
      selectItem(UrlUtils.getCurrentRelativeUrl().split("?")[0]);
  }, [selectItem, isMobile, name]);

  useEffect(() => {
    selectItem(UrlUtils.getCurrentRelativeUrl().split("?")[0]);
  }, [favouriteSports, selectItem]);

  useEffect(() => {
    let subscription = null;

    if (name !== ClientProviderEnum.Kambi && flattenedCategories?.length > 0) {
      subscription = MessageBrokerUtils.subscribe({
        channel: Channel.NAVIGATION,
        topic: Topic.Navigate,
        callback: ({ uri }) => {
          selectItem(uri);
        }
      });
    }

    return () => {
      subscription?.unsubscribe();
    };
  }, [flattenedCategories, name, dispatch, selectItem]);
};
