import { useEffect, useState } from "react";
import { iconsReady } from "../managers/iconManager";

export const useSvgLoader = () => {
  const [iconsLoaded, setIconsLoaded] = useState(false);

  useEffect(() => {
    iconsReady.promise.then(setIconsLoaded);
  }, []);

  return iconsLoaded;
};
