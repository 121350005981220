import { useSelector } from "react-redux";
import { ClientProviderEnum, Theme } from "../constants";
import { AppFeatures } from "../constants/featureFlags";
import { useFeature } from "../hooks/useFeature";
import { PageContextUtils } from "../utils/pageContextUtils";
import searchSelectors from "../redux/selectors/searchSelectors";

export class BrandUtils {
  static isPokerClient = () => {
    const { name } = PageContextUtils.getClientProviderData();

    return name === "Poker";
  };

  static isSiSportsBook = () => {
    const themeData = PageContextUtils.getThemeData();
    return themeData?.currentName === Theme.SportsIllustrated;
  };

  static isMrGreen = () => {
    const themeData = PageContextUtils.getThemeData();
    return themeData?.currentName === Theme.MrGreen;
  };

  static isSearchEnable = () => {
    const searchStateByProvider = {
      [ClientProviderEnum.Spectate]: useSelector(searchSelectors.settings)
        .enabled,
      [ClientProviderEnum.Kambi]: useFeature(AppFeatures.KAMBI_SEARCH)
    };

    const { name } = PageContextUtils.getClientProviderData();
    const isSearchEnabled = searchStateByProvider[name] || false;

    return isSearchEnabled;
  };
}
