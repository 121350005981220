import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LocalStorageUtils } from "../utils";
import { UserContextActionCreators } from "../redux/action-creators";
import { UserContextSelectors } from "../redux/selectors";

export const useMiniCashierInit = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(UserContextSelectors.isAuthenticated);
  const miniCashierEnabled = useSelector(
    UserContextSelectors.miniCashierEnabled
  );
  const updateMiniCashierDetails = useCallback(
    details =>
      dispatch(UserContextActionCreators.updateMiniCashierDetails(details)),
    [dispatch]
  );

  useEffect(() => {
    if (isAuthenticated && miniCashierEnabled) {
      const details = LocalStorageUtils.get("MiniCashierDetails") || {
        IsEligible: false
      };
      updateMiniCashierDetails(details);
    }
  }, [isAuthenticated, miniCashierEnabled, updateMiniCashierDetails]);
};
