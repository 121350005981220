import { PageContextUtils } from "../../utils";

const icon = async url => {
  const { name } = PageContextUtils.getClientProviderData();

  return fetch(url, {
    method: "GET",
    mode: "cors",
    credentials: name !== "Kambi" ? "include" : "omit",
    cache: "default"
  })
    .then(response => response.text())
    .then(response => ({ response }))
    .catch(reason => ({ errorResponse: reason }));
};

export default {
  icon
};
