import { ActionHandlerActionConstants } from "../action-constants";

const performDepositAction = buttonID => ({
  type: ActionHandlerActionConstants.PERFORM_DEPOSIT_ACTION,
  payload: { buttonID }
});

const openPokerGame = ({ buttonID, poolID }) => ({
  type: ActionHandlerActionConstants.OPEN_POKER_GAME,
  payload: { buttonID, poolID }
});

const openPokerHistory = buttonID => ({
  type: ActionHandlerActionConstants.OPEN_POKER_HISTORY,
  payload: { buttonID }
});

export default {
  performDepositAction,
  openPokerGame,
  openPokerHistory
};
