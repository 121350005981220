import LocalStorage from "local-storage";

export class LocalStorageUtils {
  static get(key) {
    if (!key) return undefined;

    return LocalStorage.get(key);
  }

  static set(key, value) {
    if (!key) return;

    LocalStorage.set(key, value);
  }

  static remove(key) {
    if (!key) return;

    LocalStorage.remove(key);
  }

  static clear() {
    LocalStorage.clear();
  }

  static on(key, fn) {
    if (key && fn) LocalStorage.on(key, fn);
  }

  static off(key, fn) {
    if (key && fn) LocalStorage.off(key, fn);
  }
}
