import { LocalStorageUtils } from "./localStorageUtils";

const SEARCH_HISTORY_KEY = "search_history";

export class SearchHistoryUtils {
  static getPersistedSearchHistory = () => {
    const existingHistory = LocalStorageUtils.get(SEARCH_HISTORY_KEY);
    if (!existingHistory) return [];

    return existingHistory;
  };

  static persistSearchHistory = history => {
    if (history) {
      LocalStorageUtils.set(SEARCH_HISTORY_KEY, history);
    }
  };

  static updateSearchHistory = searchResult => {
    if (!searchResult) return;

    const history = SearchHistoryUtils.getPersistedSearchHistory();

    const newHistory = [
      searchResult,
      ...history.filter(elem => elem.url !== searchResult.url)
    ].slice(0, 10);

    SearchHistoryUtils.persistSearchHistory(newHistory);

    return newHistory;
  };

  static clearSearchHistory = () => {
    LocalStorageUtils.remove(SEARCH_HISTORY_KEY);
  };

  static handleHistoryChange = fn => {
    if (fn) LocalStorageUtils.on(SEARCH_HISTORY_KEY, fn);
  };

  static removeHistoryChangeEventHandler(fn) {
    if (fn) LocalStorageUtils.off(SEARCH_HISTORY_KEY, fn);
  }
}
