import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UIActionCreators } from "../../../redux/action-creators";
import { UISelectors } from "../../../redux/selectors";
import { UIUtils } from "../../../utils";

export const useThemeModeHandler = () => {
  const dispatch = useDispatch();
  const themeModeSettings = useSelector(UISelectors.themeModeSettings);

  const parseThemeMode = useCallback(
    themeModeCode => {
      const themeMode = themeModeSettings?.find(
        modeSetting => modeSetting.code === themeModeCode
      );

      return themeMode;
    },
    [themeModeSettings]
  );

  const setStoreThemeMode = useCallback(
    themeMode => {
      dispatch(UIActionCreators.setThemeMode(themeMode));
    },
    [dispatch]
  );

  const setHtmlThemeMode = useCallback(
    themeMode => {
      if (themeMode) UIUtils.setHtmlThemeMode(themeModeSettings, themeMode);
    },
    [themeModeSettings]
  );

  return {
    parseThemeMode,
    setStoreThemeMode,
    setHtmlThemeMode
  };
};
