import { parse } from "url";
import { Channel, Topic } from "../constants";
import { UrlUtils, MessageBrokerUtils } from "../utils";

export class NavigationUtils {
  static openLink = (url, target, attributes) => {
    const linkElm = document.createElement("a");
    linkElm.target = target;
    linkElm.href = url;
    attributes?.forEach(element => {
      linkElm.setAttribute(element.key, element.value);
    });

    document.body.appendChild(linkElm);

    linkElm.click();
    linkElm.remove();
  };

  static navigateTo = url => {
    const funcProps = [
      {
        channel: Channel.ROUTER,
        topic: Topic.NavigateByCode,
        headers: { publisher: "userarea" },
        data: { url }
      }
    ];

    MessageBrokerUtils.publish(...funcProps);
  };

  static openExternalUrl = url => {
    if (!url) return;

    MessageBrokerUtils.publish({
      channel: Channel.CRM,
      topic: Topic.CTA_ExternalLink_Open,
      headers: {
        publisher: "UAA"
      },
      data: {
        Url: url
      }
    });
  };

  static openExternalUrlWithSCut = url => {
    const urlWithSCut = UrlUtils.getURLWithSCut(url, true);

    NavigationUtils.openExternalUrl(urlWithSCut);
  };

  static runFlowRunner = actions => {
    MessageBrokerUtils.publish({
      channel: Channel.NAVIGATION,
      topic: Topic.flowRunner,
      headers: {
        publisher: "unifiedclient"
      },
      data: {
        deepLinkHash: NavigationUtils.generateAuthedFlow(actions)
      }
    });
  };

  static generateAuthedFlow = actions => {
    const deepLinkHash = {
      area: "ua",
      channel: "navigation",
      actions: [
        {
          actionID: "login"
        },
        actions
      ]
    };
    return JSON.stringify(deepLinkHash);
  };

  static addQueryToLink = (link, query) => {
    if (!link) return undefined;
    if (!query || !(query instanceof Object) || !Object.keys(query).length)
      return link;

    const parsedURL = parse(link, true);
    if (parsedURL) {
      parsedURL.query = { ...parsedURL.query, ...query };

      return parsedURL.format();
    }

    return link;
  };
}
