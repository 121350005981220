import { NativeActionConstants } from "../action-constants";

const {
  INIT_STATE,
  SET_BIOMETRICS_FLOW_ENABLED,
  SET_BIOMETRICS_ENROLLMENT_STATUS
} = NativeActionConstants;

const initState = newState => ({
  type: INIT_STATE,
  payload: newState
});

const setBiometricsFlowEnabled = isEnabled => ({
  type: SET_BIOMETRICS_FLOW_ENABLED,
  payload: isEnabled
});

const setBiometricsEnrollmentStatus = isEnrolled => ({
  type: SET_BIOMETRICS_ENROLLMENT_STATUS,
  payload: isEnrolled
});

export default {
  initState,
  setBiometricsFlowEnabled,
  setBiometricsEnrollmentStatus
};
