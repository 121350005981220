import { Channel, OpeningMode, Topic } from "../../../constants";
import { UIActionCreators } from "../../../redux/action-creators";
import { MessageBrokerUtils } from "../../../utils";
import { AppHelpers } from "../helpers";

export class WidgetDisposeService {
  constructor(dispatch, initTimer) {
    this._dispatch = dispatch;
    this._initTimer = initTimer;
  }
  handle = () => {
    MessageBrokerUtils.subscribe({
      channel: Channel.UI,
      topic: Topic.RemoveAppContainer,
      callback: data => {
        const { openingMode, canClose } = data;

        if (!!canClose) {
          switch (openingMode) {
            case OpeningMode.RightSideDrawer:
              handleRightSideDrawer(this._dispatch, data);
              break;

            case OpeningMode.Popup: {
              handlePopup(this._initTimer);
              break;
            }
            case OpeningMode.FullScreenPopup: {
              handleFullScreenPopup(this._dispatch);
              break;
            }
            case OpeningMode.MainView: {
              handleMainView(this._dispatch);
              break;
            }
            case OpeningMode.bubble: {
              handleBubble(this._dispatch);
              break;
            }
            default:
              break;
          }
        }
      }
    });
  };
}

const handleRightSideDrawer = (dispatch, data) => {
  AppHelpers.toggleBetslipVisibility(true);
  dispatch(
    UIActionCreators.removeElementRightSideDrawer({
      ...data,
      isVisible: false,
      isMinimized: false
    })
  );
};

const handlePopup = initTimer => {
  if (initTimer?.current) {
    clearTimeout(initTimer.current);
    initTimer.current = undefined;
  }
  AppHelpers.hidePopup();
};

const handleFullScreenPopup = dispatch => {
  dispatch(UIActionCreators.createFullScreenPopup(null));
};

const handleMainView = dispatch => {
  dispatch(UIActionCreators.toggleMainViewApp(null));
};

const handleBubble = dispatch => {
  dispatch(UIActionCreators.toggleBubble(null));
};
