import { Channel, Topic } from "../constants";
import { MessageBrokerUtils } from "../utils";
import { UserContextActionCreators } from "../redux/action-creators";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BrandUtils } from "../utils/brandUtils";
import { UserContextSelectors } from "../redux/selectors";

const entryToRemovedTicket = ([id, ticket]) => ({
  id,
  type: "removed",
  data: ticket
});

export const useTournamentTicketsUpdates = () => {
  const [timeoutHandle, setTimeoutHandle] = useState(-1);
  const { userTickets } = useSelector(UserContextSelectors.userTickets);
  const ticketEntries = useMemo(
    () => Object.entries(userTickets),
    [userTickets]
  );
  const dispatch = useDispatch();

  const clearNotificationsTimeout = useCallback(() => {
    if (timeoutHandle.current !== -1) {
      clearTimeout(timeoutHandle);
      setTimeoutHandle(-1);
    }
  }, [timeoutHandle]);

  const handleLoggedOut = useCallback(() => {
    const cleanupTickets = ticketEntries.map(entryToRemovedTicket);
    dispatch(UserContextActionCreators.updateTickets(cleanupTickets));
  }, [dispatch, ticketEntries]);

  useEffect(() => {
    if (BrandUtils.isPokerClient()) {
      const ticketsSubscription = MessageBrokerUtils.subscribe({
        channel: Channel.CRM,
        topic: Topic.TOURNAMENT_TICKETS,
        callback: data => {
          console.log(`TicketsUpdated event caught, new tickets: ${data}`);
          dispatch(UserContextActionCreators.updateTickets(data));
        }
      });

      const logoutSubscription = MessageBrokerUtils.subscribe({
        channel: Channel.IDENTITY,
        topic: Topic.logoutSuccess,
        callback: handleLoggedOut
      });

      return () => {
        ticketsSubscription?.unsubscribe();
        logoutSubscription?.unsubscribe();
      };
    }
  }, [dispatch, handleLoggedOut]);

  useEffect(() => {
    if (BrandUtils.isPokerClient()) {
      if (ticketEntries.length === 0) {
        return;
      }

      const firstExpiringTicket = ticketEntries.sort(
        ([, a], [, b]) => a.expirationDate.seconds - b.expirationDate.seconds
      )[0];
      const dateTimeNowInSeconds = Math.floor(Date.now() / 1000);
      const secondsTillNextExpiration =
        firstExpiringTicket.expirationDate.seconds - dateTimeNowInSeconds;

      const newTimeoutHandle = setTimeout(() => {
        const expiredTickets = ticketEntries
          .filter(
            ([, ticket]) => ticket.expirationDate.seconds * 1000 <= Date.now()
          )
          .map(entryToRemovedTicket);
        dispatch(UserContextActionCreators.updateTickets(expiredTickets));
      }, secondsTillNextExpiration * 1000);

      setTimeoutHandle(newTimeoutHandle);
    }

    return clearNotificationsTimeout;
  }, [
    timeoutHandle,
    userTickets,
    dispatch,
    clearNotificationsTimeout,
    ticketEntries
  ]);
};
