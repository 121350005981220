import { ScreenOrientation } from "../constants";
import { UIActionCreators } from "../redux/action-creators";
import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";

const mediaToMatch = "(orientation: portrait)";

const addEventListener = handler => {
  const objectToObserve = window.matchMedia(mediaToMatch);
  if (!objectToObserve) {
    console.error(
      "useDeviceOrientation:addEventListener: Watching device orientation change is not working."
    );
    return objectToObserve;
  }

  if (typeof objectToObserve.addEventListener === "function") {
    objectToObserve.addEventListener("change", handler);
  } else if (typeof objectToObserve.addListener === "function") {
    objectToObserve.addListener(handler);
  } else {
    console.error(
      "useDeviceOrientation:addEventListener: addListener and addEventListener functions don't exist."
    );
    return null;
  }

  return objectToObserve;
};

const removeEventListener = (objectToObserve, handler) => {
  if (typeof objectToObserve.removeEventListener === "function") {
    objectToObserve.removeEventListener("change", handler);
  } else if (typeof objectToObserve.removeListener === "function") {
    objectToObserve.removeListener(handler);
  } else {
    console.error(
      "useDeviceOrientation:removeEventListener: addListener and addEventListener functions don't exist."
    );
    return null;
  }
};

export const useDeviceOrientation = () => {
  const dispatch = useDispatch();

  const updateDeviceOrientation = useCallback(
    e => {
      dispatch(
        UIActionCreators.setDeviceOrientation(
          e.matches ? ScreenOrientation.Portrait : ScreenOrientation.Landscape
        )
      );
    },
    [dispatch]
  );

  useEffect(() => {
    updateDeviceOrientation(window.matchMedia(mediaToMatch));
  }, [updateDeviceOrientation]);

  useEffect(() => {
    const objectToObserve = addEventListener(updateDeviceOrientation);
    return () => {
      removeEventListener(objectToObserve, updateDeviceOrientation);
    };
  }, [dispatch, updateDeviceOrientation]);
};
