import { Channel, Topic } from "../constants";
import { useCallback, useEffect } from "react";

import { MessageBrokerUtils } from "../utils";
import { UserContextActionCreators } from "../redux/action-creators";
import { useDispatch } from "react-redux";

export const useIsNative = () => {
  const dispatch = useDispatch();

  const updateIsNative = useCallback(
    deviceInformation => {
      const isNative =
        !!deviceInformation &&
        deviceInformation.isMobile &&
        deviceInformation.isHybrid;

      dispatch(UserContextActionCreators.updateIsNative(isNative));

      const isNativeIOS =
        !!deviceInformation &&
        deviceInformation.isMobile &&
        deviceInformation.isHybrid &&
        deviceInformation.isIOS;

      dispatch(UserContextActionCreators.updateIsNativeIOS(isNativeIOS));
    },
    [dispatch]
  );

  useEffect(() => {
    const isNativeSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.UI,
      topic: Topic.DeviceInformation,
      callback: updateIsNative
    });

    return () => {
      isNativeSubscription?.unsubscribe();
    };
  }, [updateIsNative]);
};
