import { DrawerActionType } from "../../components/ControlCenter";
import { AccountActionConstants } from "../action-constants";

const toggleAccountDrawer = (actionType = DrawerActionType.External) => ({
  type: AccountActionConstants.TOGGLE_ACCOUNT_DRAWER,
  payload: { actionType }
});

const toggleRewardDrawer = () => ({
  type: AccountActionConstants.TOOGLE_ACCOUNT_REWARDS_DRAWER
});

const toggleMiniCashierDrawer = () => ({
  type: AccountActionConstants.TOGGLE_MINI_CASHIER_DRAWER
});

export default {
  toggleAccountDrawer,
  toggleMiniCashierDrawer,
  toggleRewardDrawer
};
