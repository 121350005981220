const getLanguageSwitcherReducer = state => state.languageSwitcher;

export default {
  lang: store => getLanguageSwitcherReducer(store).lang,
  langIso3: store => getLanguageSwitcherReducer(store).langIso3,
  data: store => getLanguageSwitcherReducer(store).data,
  publicationId: store => getLanguageSwitcherReducer(store).publicationId,
  dropDownExpand: store => !!getLanguageSwitcherReducer(store).dropDownExpand,
  dialects: store => getLanguageSwitcherReducer(store).dialects
};
