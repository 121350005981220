import PropTypes from "prop-types";
import React from "react";
import { SVGIcon2 } from "../../common/SVGIconV2";

export const MenuArrow = ({ arrowText }) => (
  <span className="menu-arrow">
    <SVGIcon2 className="cool_arrow" iconName="cool_arrow">
      {arrowText && <span className="arrow-text">{arrowText}</span>}
    </SVGIcon2>
  </span>
);

MenuArrow.propTypes = {
  arrowText: PropTypes.string
};
