import { Channel, Topic } from "../constants";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MessageBrokerUtils } from "../utils";
import { UserContextActionCreators } from "../redux/action-creators";
import { UserContextSelectors } from "../redux/selectors";

export const useMiniCashierDetailsSubscription = () => {
  const isAuthenticated = useSelector(UserContextSelectors.isAuthenticated);
  const dispatch = useDispatch();
  const updateMiniCashierDetails = useCallback(
    miniCashierDetails => {
      dispatch(
        UserContextActionCreators.updateMiniCashierDetails(miniCashierDetails)
      );
    },
    [dispatch]
  );

  useEffect(() => {
    let miniCashierDetailsSubscription;
    if (isAuthenticated)
      miniCashierDetailsSubscription = MessageBrokerUtils.subscribe({
        channel: Channel.MINI_CASHIER,
        topic: Topic.DetailsRetrieved,
        callback: updateMiniCashierDetails
      });

    return () => {
      if (miniCashierDetailsSubscription) {
        console.log("[APP.js] - Unsubscribing from MiniCashier channel...");
        miniCashierDetailsSubscription?.unsubscribe();
      }
    };
  }, [isAuthenticated, updateMiniCashierDetails]);
};
