import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { UISelectors } from "../redux/selectors";

export const useAppNoScroll = () => {
  const defaultNoScrollClass = "disable-scroll";
  const noScrolls = useSelector(UISelectors.noScrolls);

  const setSelectorNoScroll = useCallback(
    (selector, isNoScroll, noScrollClass) => {
      const element = getDocumentElement(selector);

      if (element) {
        if (isNoScroll) element.classList.add(noScrollClass);
        else element.classList.remove(noScrollClass);
      }
    },
    []
  );

  const setScrollbarNoScroll = useCallback((selector, isNoScroll) => {
    const element = getDocumentElement(selector);

    if (element) {
      const isUCContentAreaNoScroll = selector === ".uc-content-area";

      if (isNoScroll && isUCContentAreaNoScroll) {
        const scrollbarWidth = computeScrollbarWidth();
        element.style.paddingRight = `${scrollbarWidth}px`;
      } else element.style.paddingRight = "0";
    }
  }, []);

  const setHtmlNoScroll = useCallback((selector, isNoScroll, noScrollClass) => {
    const isHtmlNoScroll = selector === "body" || selector === ".ucf_mobile";

    if (isHtmlNoScroll) {
      const element = getDocumentElement("html");
      if (isNoScroll) element.classList.add(noScrollClass);
      else element.classList.remove(noScrollClass);
    }
  }, []);

  useEffect(() => {
    if (noScrolls?.length > 0) {
      const setNoScroll = noScroll => {
        const noScrollClass =
          noScroll.options?.noScrollClass || defaultNoScrollClass;

        setHtmlNoScroll(noScroll.selector, noScroll.isNoScroll, noScrollClass);

        setSelectorNoScroll(
          noScroll.selector,
          noScroll.isNoScroll,
          noScrollClass
        );

        setScrollbarNoScroll(noScroll.selector, noScroll.isNoScroll);
      };

      const processNoScroll = isNoScroll =>
        noScrolls
          .filter(noScroll => !!noScroll.isNoScroll === isNoScroll)
          .forEach(noScroll => {
            setNoScroll(noScroll);
          });

      processNoScroll(false);
      processNoScroll(true);
    }
  }, [noScrolls, setHtmlNoScroll, setSelectorNoScroll, setScrollbarNoScroll]);

  const computeScrollbarWidth = () => {
    let inner = document.createElement("p");
    inner.style.width = "100%";
    inner.style.height = "200px";
    let outer = document.createElement("div");
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);
    document.body.appendChild(outer);
    let w1 = inner.offsetWidth;
    outer.style.overflow = "scroll";
    let w2 = inner.offsetWidth;
    if (w1 === w2) w2 = outer.clientWidth;
    document.body.removeChild(outer);

    return w1 - w2;
  };

  const getDocumentElement = selector => {
    return typeof selector === "object"
      ? selector
      : document.querySelector(selector);
  };
};
