import { LocalizationUtils } from "../utils";

export const getTranslations = async (language, dialects) => {
  let dialect = null;

  if (dialects && dialects.length > 0) {
    dialect = dialects.find(x => {
      const languageDialect = x.split("-");
      const isDialectLanguageFound =
        languageDialect.length > 1 &&
        languageDialect[0].toLowerCase() === language.toLowerCase();

      return isDialectLanguageFound ? x : null;
    });
  }

  const translation = dialect || LocalizationUtils.getMappedLanguage(lang);

  const messages = await import(
    /* webpackChunkName: "locale/[request]" */
    `./compiled/${translation.toLowerCase()}.json`
  );

  return messages;
};

const { dialects,lang } = window.__UAA_STATE__?.languageSwitcher || {};

const localePromise  = getTranslations(lang, dialects);;

export { localePromise  };
