export class Item {
  constructor(item, keepChildrenRefs) {
    this.ID = item.ID;
    this.URL = item.URL;
    this.termKey = item.termKey;
    this.title = item.title;
    this.iconKey = item.iconKey;
    this.iconURL = item.iconURL;
    this.items = keepChildrenRefs
      ? item.items
      : item.items?.map(item => new Item(item));
    this.target = item.target;
    this.href = item.href;
    this.active =
      typeof item.active === "undefined" || item.active === null
        ? true
        : item.active;
    this.sportID = item.sportID || item.ID;
    this.fullURL = _computeFullURL(item);
    this.label = item.label;
    this.termKeyPrefix = item.termKeyPrefix || "";
  }
}

const _computeFullURL = item => {
  if (item.URL?.includes("#") || !item.href) return item.URL;

  return `${item.URL || "/"}#${item.href}`;
};
