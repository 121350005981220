import {
  Channel,
  ROOT,
  SC_HOME,
  ClientProviderEnum,
  Topic
} from "../constants";
import {
  MenuSelectors,
  UISelectors,
  UserContextSelectors
} from "../redux/selectors";
import { MessageBrokerUtils, PageContextUtils, UrlUtils } from "../utils";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HighlightingMatch } from "../components/Menu";
import { MenuActionCreators } from "../redux/action-creators";

const menuMappings = [
  {
    hash: "#/filter/all/all/all/all/in-play",
    path: "/live-betting/"
  },
  {
    hash: "#/filter/all/all/all/all/in-play",
    path: "/live/"
  },
  {
    hash: "#/filter/all/all/all/all/in-play",
    path: "/pariuri-live/"
  },
  {
    hash: "#/filter/all/all/all/all/in-play",
    path: "/apuestas-en-vivo/"
  }
];

const findMatch = (items, uri, links) => {
  let index = 0;
  const matches = [];
  if (!uri || typeof uri !== "string") return null;

  const { pathname, hash } = new URL(
    UrlUtils.getURLWithoutLang(uri),
    window.location.origin
  );

  if (
    (pathname === ROOT && !hash) ||
    `${pathname}${hash}` === `${ROOT}${SC_HOME}`
  ) {
    const homeItem = items?.find(
      item => UrlUtils.getURLWithoutLang(item.URL) === links?.home
    );
    if (homeItem) {
      matches.push(
        new HighlightingMatch({
          order: ++index,
          item: homeItem,
          length: UrlUtils.getURLWithoutLang(homeItem.URL)?.length || 0
        })
      );
    }
  } else {
    items?.forEach(item => {
      if (!item.URL) return null;
      const itemUrl = new URL(
        UrlUtils.getURLWithoutLang(item.URL),
        window.location.origin
      );
      itemUrl.hash = itemUrl.hash || item.href || "";

      const { pathname: itemUrlPathname, hash: itemUrlHash } = itemUrl;

      const itemPathWithHash = `${itemUrlPathname}${itemUrlHash || ""}`;

      if (itemUrlPathname !== ROOT) {
        var menuMapping = menuMappings.find(
          mapping => mapping.path === itemUrlPathname
        );
        var locationMapping = menuMappings.find(
          mapping => mapping.hash === hash
        );
        if (menuMapping && locationMapping) {
          matches.push(
            new HighlightingMatch({
              order: ++index,
              item,
              length: itemPathWithHash?.length || 0
            })
          );
          return;
        }
        if (pathname?.startsWith(itemUrlPathname)) {
          matches.push(
            new HighlightingMatch({
              order: ++index,
              item,
              length: itemPathWithHash?.length || 0
            })
          );
          return;
        }
      }

      if (itemUrlHash) {
        if (hash?.startsWith(itemUrlHash)) {
          matches.push(
            new HighlightingMatch({
              order: ++index,
              item,
              length: itemPathWithHash?.length || 0
            })
          );
        }
      }
    });
  }

  const matchesSortedByLength = matches.sort((a, b) => b.length - a.length);
  const firstMatch =
    (matchesSortedByLength.length > 0 && matchesSortedByLength[0]) || null;
  if (!firstMatch) return null;

  return matchesSortedByLength.filter(
    match => match.length === firstMatch.length
  )[0]?.item;
};

export const useKambiMenuSelection = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(UserContextSelectors.isMobile);
  const links = useSelector(UISelectors.links);
  const flattenedCategories = useSelector(MenuSelectors.flattenedCategories);
  const currentSelectedItem = useSelector(MenuSelectors.selectedItem);
  const { name } = PageContextUtils.getClientProviderData();

  const selectItem = useCallback(
    uri => {
      if (
        links &&
        !(Object.keys(links).length === 0 && links.constructor === Object)
      ) {
        if (uri.includes("bethistory")) {
          dispatch(MenuActionCreators.select(null));
          return;
        }

        if (
          currentSelectedItem &&
          currentSelectedItem?.fullURL === UrlUtils.getRelativeURL(uri)
        ) {
          return;
        }

        const selectedItem = findMatch(flattenedCategories, uri, links);
        if (selectedItem && selectedItem !== currentSelectedItem) {
          dispatch(MenuActionCreators.select(selectedItem));
        }
      }
    },
    [links, currentSelectedItem, flattenedCategories, dispatch]
  );

  useEffect(() => {
    if (name === ClientProviderEnum.Kambi) selectItem(window.location.href);
  }, [isMobile, flattenedCategories, name, selectItem]);

  useEffect(() => {
    let subscription = null;

    if (name === ClientProviderEnum.Kambi && flattenedCategories?.length > 0) {
      subscription = MessageBrokerUtils.subscribe({
        channel: Channel.NAVIGATION,
        topic: Topic.Navigate,
        callback: ({ uri }) => {
          selectItem(uri);
        }
      });
    }

    return () => {
      subscription?.unsubscribe();
    };
  }, [flattenedCategories, name, dispatch, selectItem]);

  useEffect(() => {
    if (name === ClientProviderEnum.Kambi) {
      window.addEventListener("hashchange", () => {
        selectItem(window.location.href);
      });
    }
  }, [name, selectItem]);
};
