const getHeaderReducer = state => state.header;

export default {
  path: store => getHeaderReducer(store).path,
  lang: store => getHeaderReducer(store).lang,
  bodyClassName: store => getHeaderReducer(store).bodyClassName,
  betsCount: store => getHeaderReducer(store).betsCount,
  headerHeight: store => getHeaderReducer(store).headerHeight,
  isCollapsedCrossActivity: store =>
    getHeaderReducer(store).isCollapsedCrossActivity
};
