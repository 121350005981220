import { createSelector } from "reselect";

const getOverlayReducer = state => state.overlay;

const selectors = {
  overlays: store => getOverlayReducer(store).overlays
};

const isVisible = createSelector([selectors.overlays], overlays => {
  return overlays.length > 0;
});

const current = createSelector([selectors.overlays], overlays => {
  return overlays.length === 0 ? null : overlays[overlays.length - 1];
});

export default { ...selectors, current, isVisible };
