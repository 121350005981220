import { EmptyComponent } from "../../components/common/EmptyComponent";
import Loadable from "react-loadable";

export const loadHomeSection = (brandId, clientProvider) =>
  Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "[request]" */
        `../Brands/${brandId}/${clientProvider}/HomeSection.layout`
      ),
    loading: EmptyComponent
  });

export const preload = ({ clientProvider, brandId }) => {
  loadHomeSection(brandId, clientProvider)?.preload();
};
