import MenuItemLogic from "../MenuItem.logic";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";

export const HighlightedText = ({ text }) => {
  const { formatMessage } = useIntl();
  if (!text) {
    return null;
  }
  const formattedHighlightedText = `${MenuItemLogic.getDisplayedText(
    text,
    formatMessage
  )} `;

  return <span className="liveColor">{formattedHighlightedText}</span>;
};

HighlightedText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};
