export default {
  CLOSE_DRAWERS: "controlCenter/CLOSE_DRAWERS",
  FULL_HIDE: "controlCenter/FULL_HIDE",
  FULL_READY: "controlCenter/FULL_READY",
  FULL_SHOW: "controlCenter/FULL_SHOW",
  LOGOUT: "controlCenter/LOGOUT",
  MINI_HIDE: "controlCenter/MINI_HIDE",
  MINI_READY: "controlCenter/MINI_READY",
  MINI_SHOW: "controlCenter/MINI_SHOW",
  SET_ENABLED: "controlCenter/SET_ENABLED",
  SET_ERROR: "controlCenter/ERROR",
  SET_INITIATOR_SOURCE: "controlCenter/SET_INITIATOR_SOURCE"
};
