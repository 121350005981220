import { PageContextUtils } from ".";
import { Theme } from "../constants";

export class ThemeUtils {
  static getKeyValueByTheme = value => {
    const { currentName = Theme.Default } = PageContextUtils.getThemeData();

    return `${value}${currentName === Theme.SportsIllustrated ? "_si" : ""}`;
  };

  static isSITheme = () => {
    const { currentName = Theme.Default } = PageContextUtils.getThemeData();

    return currentName === Theme.SportsIllustrated;
  };

  static isDefaultTheme = () => {
    const { currentName = Theme.Default } = PageContextUtils.getThemeData();

    return currentName === Theme.Default;
  };

  static isMrGreenTheme = () => {
    const { currentName = Theme.Default } = PageContextUtils.getThemeData();

    return currentName === Theme.MrGreen;
  };

  static isPokerTheme = () => {
    const { currentName = Theme.Default } = PageContextUtils.getThemeData();

    return currentName === Theme.Poker;
  };
}
