import { useEffect, useState } from "react";

import { useNonReactElementExist } from "./useNonReactElementExist";
import { useNoScroll } from "./useNoScroll";

export const useHtmlElementNoScroll = () => {
  const [isScrollingDisabled, setIsScrollingDisabled] = useState(false);

  const isWrapDivExist = useNonReactElementExist({
    elementToWatch: "#WrapDiv"
  });

  useEffect(() => {
    setIsScrollingDisabled(isWrapDivExist);
  }, [isWrapDivExist]);

  useNoScroll("wrapDiv", isScrollingDisabled, "body");
};
