export class StorageDataStore {
  constructor(storage, storeName) {
    this.storage = storage;
    this.storeName = storeName;
  }

  get = () => {
    return this.storage.getItem(this.storeName);
  };

  set = data => {
    this.storage.setItem(this.storeName, data);
  };

  remove = () => {
    this.storage.removeItem(this.storeName);
  };
}
