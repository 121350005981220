import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { UIActionCreators } from "../redux/action-creators";

export const useNoScroll = (id, isNoScroll, selector) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      UIActionCreators.setNoScroll({
        id,
        selector: selector || "body",
        isNoScroll
      })
    );
  }, [id, selector, isNoScroll, dispatch]);
};
