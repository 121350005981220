import { MessageBrokerUtils, NavigationUtils } from "../utils";
import { useCallback, useEffect } from "react";
import { Channel, ClientPageType, Topic } from "../constants";
import { useSelector } from "react-redux";
import { UISelectors, UserContextSelectors } from "../redux/selectors";
import { Brand } from "../configurations";
import { BrandUtils } from "../utils/brandUtils";
import { useDispatch } from "react-redux";
import { ControlCenterActionCreators } from "../redux/action-creators";

export const useClientPageNavigation = data => {
  const brandId = useSelector(UserContextSelectors.brandId);
  const links = useSelector(UISelectors.links);
  const dispatch = useDispatch();

  const openRGPolicy = useCallback(() => {
    if (brandId === Brand.Sport_Com && !BrandUtils.isPokerClient()) {
      dispatch(ControlCenterActionCreators.closeDrawers());
      NavigationUtils.openLink(links.responsibleGaming, "_self");
    } else NavigationUtils.openExternalUrl(links.responsibleGaming);
  }, [brandId, dispatch, links.responsibleGaming]);

  useEffect(() => {
    const subscription = MessageBrokerUtils.subscribe({
      channel: Channel.NAVIGATION,
      topic: Topic.openClientPage,
      callback: ({ clientPageType } = {}) => {
        if (!clientPageType) return;
        switch (clientPageType) {
          case ClientPageType.rgPolicy: {
            openRGPolicy();
            break;
          }
        }
      }
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [data, openRGPolicy]);
};
