import { Channel, DialogType, Topic } from "../../constants";
import { MessageBrokerUtils, TrackingUtils } from "../../utils";

export class AppHelpers {
  static hidePopup = () => {
    MessageBrokerUtils.publish({
      channel: Channel.UI,
      topic: Topic.Dialog,
      headers: {
        publisher: "UAA"
      },
      data: {
        dialogType: DialogType.Hide
      }
    });
  };

  static toggleBetslipVisibility = show => {
    MessageBrokerUtils.publish({
      channel: Channel.PLAYER,
      topic: Topic.showBetslip,
      headers: { publisher: "UAA" },
      data: { show }
    });
  };

  static appContainerReady = data => {
    MessageBrokerUtils.publish({
      channel: Channel.UI,
      topic: Topic.appContainerReady,
      headers: {
        publisher: "UAA"
      },
      data
    });
  };

  static showRedirectMFTPopup = (mft, action) => {
    MessageBrokerUtils.publish({
      channel: Channel.UI,
      topic: Topic.Dialog,
      headers: {
        publisher: "UAA"
      },
      data: {
        dialogType: DialogType.RedirectMft,
        options: {
          mft: mft,
          action: () => {
            TrackingUtils.sendAnalytics({
              dataCategory: "Deposit redirect overlay",
              dataAction: "view",
              dataLabel: "back to pop-up"
            });
            if (typeof action === "function") action();
            else {
              MessageBrokerUtils.publish({
                channel: Channel.NAVIGATION,
                topic: Topic.overlayClicked,
                headers: {
                  publisher: "UAA"
                }
              });
            }
          }
        }
      }
    });
  };
}
