///<reference path="../typings/library.manager.d.ts" />

import "./styles/main.scss";
import "./publicPathLoader";
import "./styles/themes/themeLoader";
import "regenerator-runtime/runtime";
import { EmptyComponent } from "./components/common/EmptyComponent";
import Loadable from "react-loadable";
import { StoreLoader } from "./redux";
import { PageContextUtils, UIUtils } from "./utils";
import { localePromise } from "./localization";
import { preloadHeaderResources } from "./segmentedComponents/Header/loader";
import { LeftAreaPC, MenuChunk } from "./components/LeftAreaPC";
import emptyState from "./redux/store/emptyState";
import { preloadResources as preloadRewardsResources } from "./segmentedComponents/Rewards/loader";
import { preload as preloadHomeSection } from "./segmentedComponents/HomeSection/loader";
import { preload as preloadLoyaltySection } from "./segmentedComponents/LoyaltySection/loader";
import { preload as preloadSecondaryMenu } from "./segmentedComponents/SecondaryMenu/loader";
import { preload as preloadSILinks } from "./segmentedComponents/SI-links/loader";
import { ConfigPromise } from "./hooks";
import { processClientIcons } from "./managers/iconManager";

export const preloadMenuResources = () => {
  const isPC = emptyState.userContext.deviceType.toLocaleLowerCase() === "pc";
  if (isPC) {
    const brandId = window.__UAA_STATE__?.userContext.brandId;
    const { name } = PageContextUtils.getClientProviderData();

    preloadRewardsResources({ clientProvider: name, brandId });
    preloadHomeSection({ clientProvider: name, brandId });
    preloadLoyaltySection({ clientProvider: name, brandId });
    preloadSecondaryMenu({ clientProvider: name, brandId });
    MenuChunk.preload();
  }
};

export const preloadLeftArea = () => {
  const { name } = PageContextUtils.getClientProviderData();
  const brandId = window.__UAA_STATE__?.userContext.brandId;

  const deviceType = emptyState.userContext.deviceType;
  if (deviceType.toLocaleLowerCase() === "pc") {
    processClientIcons();

    LeftAreaPC.preload();
    preloadSILinks({ isMobile: false, brandId, clientProvider: name });
  }
};

const preloadUserareaEntry = () => {
  const App = Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "infra/userarea.app" */

        "./App"
      ),
    loading: EmptyComponent
  });
  App.preload();
  return App;
};

const preload = () => {
  preloadHeaderResources();
  preloadMenuResources();
  preloadLeftArea();
  return preloadUserareaEntry();
};

const storePromise = StoreLoader.createStoreAsync();

const AppComponent = preload();

const renderAppPromise = import(
  /* webpackChunkName: "infra/userarea-renderer" */
  "./app-renderer"
);

const render = async () => {
  if (UIUtils.isClientMode()) return;
  const { renderApplication } = await renderAppPromise;
  const criticalPromises = Promise.all([
    storePromise,
    localePromise,
    ConfigPromise
  ]);
  await renderApplication(criticalPromises, AppComponent);
};

render();
