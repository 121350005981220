import { useEffect, useState } from "react";

// Hook
let cachedScripts = [];
export const useScript = (initData, enabled = true) => {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false
  });

  useEffect(() => {
    const { attributes, handlers } = initData || {};
    if (!enabled || !attributes || !attributes?.src) return;
    if (cachedScripts.includes(attributes.src) && !state.error) {
      setState({
        loaded: true,
        error: false
      });

      if (typeof handlers?.onload === "function") {
        handlers.onload();
      }
    } else {
      let script = document.createElement("script");
      cachedScripts.push(attributes.src);

      Object.keys(handlers || {}).map(attr => (script[attr] = handlers[attr]));

      Object.keys(attributes).map(attr =>
        script.setAttribute(attr, attributes[attr])
      );

      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false
        });
      };

      const onScriptError = () => {
        const index = cachedScripts.indexOf(attributes.src);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();
        if (!state.loaded || !state.error) {
          setState({
            loaded: true,
            error: true
          });
        }
      };

      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onScriptError);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener("load", onScriptLoad);
        script.removeEventListener("error", onScriptError);
      };
    }
  }, [enabled, initData, state.error, state.loaded]);

  return { loaded: state.loaded, error: state.error };
};
