import Loadable from "react-loadable";

import { EmptyComponent } from "../../components/common/EmptyComponent";
import { BrandUtils } from "../../utils/brandUtils";

export const loadLoyaltySection = (brandId, clientProvider) =>
  Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "[request]" */
        `../Brands/${brandId}/${clientProvider}/LoyaltySection.layout`
      ),
    loading: EmptyComponent
  });

export const preload = ({ clientProvider, brandId }) => {
  if (BrandUtils.isPokerClient())
    loadLoyaltySection(brandId, clientProvider)?.preload();
};
