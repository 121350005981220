import { SearchActionConstants } from "../action-constants";

const request = searchTerm => ({
  type: SearchActionConstants.REQUEST,
  payload: searchTerm
});

const requestFulfilled = data => ({
  type: SearchActionConstants.REQUEST_FULFILLED,
  payload: data
});

const requestError = error => ({
  type: SearchActionConstants.REQUEST_ERROR,
  payload: error
});

const clearResults = () => ({
  type: SearchActionConstants.CLEAR_RESULTS
});

const initHistory = () => ({
  type: SearchActionConstants.INIT_HISTORY
});

const setHistory = searchTerm => ({
  type: SearchActionConstants.SET_HISTORY,
  payload: searchTerm
});

const clearHistory = () => ({
  type: SearchActionConstants.CLEAR_HISTORY
});

const updateHistory = newHistory => ({
  type: SearchActionConstants.UPDATE_HISTORY,
  payload: newHistory
});

const setTerm = searchTerm => ({
  type: SearchActionConstants.SET_TERM,
  payload: searchTerm
});

const toggleSearchDrawer = flag => ({
  type: SearchActionConstants.TOGGLE_SEARCH_DRAWER,
  payload: flag
});

const setSettings = settings => ({
  type: SearchActionConstants.SET_SETTINGS,
  payload: settings
});

export default {
  clearHistory,
  clearResults,
  initHistory,
  request,
  requestError,
  requestFulfilled,
  setHistory,
  setTerm,
  updateHistory,
  toggleSearchDrawer,
  setSettings
};
