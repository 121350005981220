import Loadable from "react-loadable";
import { EmptyComponent } from "../../components/common/EmptyComponent";
import { ThemeUtils } from "../../utils";

export const loadSILinksComponent = (isMobile, brandId, clientProvider) =>
  Loadable({
    loader: async () => {
      try {
        const component = await import(
          /* webpackChunkName: "[request]" */
          `../Brands/${brandId}/${clientProvider}/SILinks.${
            isMobile ? "mobile" : "pc"
          }.layout`
        );

        return component;
      } catch (e) {
        console.warn(
          "Userarea: loadSILinksComponent: warning: SILink component is not defined.",
          e
        );

        return EmptyComponent;
      }
    },
    loading: EmptyComponent
  });

export const preload = ({ isMobile, clientProvider, brandId }) => {
  if (!ThemeUtils.isSITheme()) {
    return;
  }

  const Component = loadSILinksComponent(isMobile, brandId, clientProvider);

  Component?.preload();
};
