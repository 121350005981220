import { ControlCenterActionConstants } from "../action-constants";

const setInitiatorSource = initiatorSource => ({
  type: ControlCenterActionConstants.SET_INITIATOR_SOURCE,
  payload: initiatorSource
});

const setMiniReady = payload => ({
  type: ControlCenterActionConstants.MINI_READY,
  payload
});

const setFullReady = payload => ({
  type: ControlCenterActionConstants.FULL_READY,
  payload
});

const showMini = (options = {}) => ({
  type: ControlCenterActionConstants.MINI_SHOW,
  payload: options
});

const hideMini = (options = {}) => ({
  type: ControlCenterActionConstants.MINI_HIDE,
  payload: options
});

const showFull = (options = {}) => ({
  type: ControlCenterActionConstants.FULL_SHOW,
  payload: options
});

const hideFull = (options = {}) => ({
  type: ControlCenterActionConstants.FULL_HIDE,
  payload: options
});

const setError = error => ({
  type: ControlCenterActionConstants.SET_ERROR,
  payload: error
});

const logout = () => ({
  type: ControlCenterActionConstants.LOGOUT
});

const setEnabled = isEnabled => ({
  type: ControlCenterActionConstants.SET_ENABLED,
  payload: isEnabled
});

const closeDrawers = () => ({
  type: ControlCenterActionConstants.CLOSE_DRAWERS
});

export default {
  closeDrawers,
  hideFull,
  hideMini,
  logout,
  setEnabled,
  setError,
  setFullReady,
  setInitiatorSource,
  setMiniReady,
  showFull,
  showMini
};
