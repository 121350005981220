import { EmptyComponent } from "../common/EmptyComponent";
import Loadable from "react-loadable";

export const LeftAreaPC = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "menu/pc/left-area" */
      "./LeftAreaPC"
    ),
  loading: EmptyComponent
});

export const MenuChunk = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "menu/pc/menu" */
      "../Menu/Menu"
    ),
  loading: EmptyComponent
});
