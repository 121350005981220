export default {
  SELECT: "menu/SELECT",
  SET_SPORT_CATEGORIES: "menu/SET_SPORT_CATEGORIES",
  SET_FAVOURITE_SPORTS: "menu/SET_FAVOURITE_SPORTS",
  SET_FAVOURITE_SPORTS_SELECTABLE: "menu/SET_FAVOURITE_SPORTS_SELECTABLE",
  SET_FAVOURITE_SPORTS_RUNNING_INSIDE_INNER_MENU:
    "menu/SET_FAVOURITE_SPORTS_RUNNING_INSIDE_INNER_MENU",
  SET_HOME_SECTION_CATEGORIES: "menu/SET_HOME_SECTION_CATEGORIES",
  SET_INNER_MENU_IGNORE_CLASS: "menu/SET_INNER_MENU_IGNORE_CLASS",
  DRAWERS_RENDERING: "menu/DRAWERS_RENDERING",
  DRAWERS_LOADED: "menu/DRAWERS_LOADED",
  // hamburger menu
  SHOW_HAMBURGER_MENU: "menu/SHOW_HAMBURGER_MENU",
  HIDE_HAMBURGER_MENU: "menu/HIDE_HAMBURGER_MENU",
  // language menu
  SHOW_LANGUAGE_MENU: "menu/SHOW_LANGUAGE_MENU",
  HIDE_LANGUAGE_MENU: "menu/HIDE_LANGUAGE_MENU",
  // odds menu
  SHOW_ODDS_MENU: "menu/SHOW_ODDS_MENU",
  HIDE_ODDS_MENU: "menu/HIDE_ODDS_MENU",
  // // sport az menu
  SHOW_QUICK_LINKS_INNER_MENU: "menu/SHOW_QUICK_LINKS_INNER_MENU",
  HIDE_QUICK_LINKS_INNER_MENU: "menu/HIDE_QUICK_LINKS_INNER_MENU",
  // language menu
  SHOW_APPEARANCE_MENU: "menu/SHOW_APPEARANCE_MENU",
  HIDE_APPEARANCE_MENU: "menu/HIDE_APPEARANCE_MENU",
  SET_NUMBER_OF_DISPLAYED_ITEMS: "menu/SET_NUMBER_OF_DISPLAYED_ITEMS",
  SET_MENU_ITEMS: "menu/SET_MENU_ITEMS"
};
