import React from "react";
import { EmptyComponent } from "../common/EmptyComponent";
import Loadable from "react-loadable";

export const LanguagesMenu = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "menu/language" */
      "./LanguagesMenu"
    ),
  loading: EmptyComponent
});

export const LanguagesMenuPC = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "menu/pc/languages" */

      "./LanguagesMenu.pc"
    ),
  render(loaded, props) {
    let Component = loaded.LanguagesMenuPC;
    return <Component {...props} />;
  },
  loading: EmptyComponent
});

export const LanguagesMenuMobile = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "menu/mobile/languages" */

      "./LanguagesMenu.mobile"
    ),
  render(loaded, props) {
    let Component = loaded.LanguagesMenuMobile;
    return <Component {...props} />;
  },
  loading: EmptyComponent
});
