import { PageContextUtils } from "../../utils";
import { GeneralUtils } from "../../utils/generalUtils";
import { NativeUtils } from "../../utils/nativeUtils";
import { UIUtils } from "../../utils/uiUtils";
import { WIDTH_TRANSITION_VALUE } from "../../constants";
import { ThemeModeUtils } from "../../utils";

const getSearchServiceUrl = function () {
  const { searchServiceUri } = PageContextUtils.getClientProviderData();

  return searchServiceUri;
};

const { isIOS } = PageContextUtils.getDeviceData();

export default {
  account: {
    drawerActive: false,
    initialized: false,
    miniCashierDrawerActive: false,
    rewardDrawerActive: false
  },
  header: {
    betsCount: undefined,
    dropDownExpand: false,
    lang: "",
    headerHeight: null,
    isCollapsedCrossActivity: false
  },
  languageSwitcher: {
    data: {},
    dropDownExpand: false,
    lang: "",
    langIso3: "",
    publicationId: undefined,
    dialects: []
  },
  stateSwitcher: {
    selectedState: {},
    states: []
  },
  menu: {
    homeSectionCategories: null,
    loyaltySectionCategories: null,
    innerMenuIgnoreClass: null,
    sportCategories: GeneralUtils.mapMenuItems(
      window.sportClientDataProvider?.menu?.items
    ),
    numberOfDisplayedItems: null,
    favouriteSports: [],
    favouriteSportsSelectable: true,
    favouriteSportsRunningInsideInnerMenu: false,
    hamburgerMenuVisible: false,
    languageMenuVisible: false,
    oddsMenuVisible: false,
    sportAZMenuVisible: false,
    appearanceMenuVisible: false,
    menuItems: [],
    drawersRendering: false,
    drawersLoaded: false
  },
  overlay: {
    overlays: []
  },
  realityCheck: {
    netLossAmount: undefined,
    show: false
  },
  controlCenter: {
    error: null,
    fullCorrelationId: null,
    fullOpenScope: null,
    fullRendered: false,
    fullVisible: false,
    miniCorrelationId: null,
    miniOpenScope: null,
    miniRendered: false,
    miniVisible: false,
    enabled: false
  },
  search: {
    errors: {},
    historySearchTerms: [],
    results: [],
    searchActive: false,
    searchUrl: getSearchServiceUrl(),
    settings: window.sportClientDataProvider?.search || {}
  },
  ui: {
    clientInitialized: false,
    fullScreenPopup: null,
    links: {},
    config: {},
    rightSideDrawer: [],
    mainViewApp: null,
    screenType: null,
    touchIdVisible: false,
    touchIdData: {
      enrollmentMode: false,
      callbackUrl: undefined
    },
    panicTooltipClosed: false,
    bubble: null,
    lastBubblePosition: null,
    userInteracted: GeneralUtils.isPrerenderRequest(),
    isThemeModeEnabled: ThemeModeUtils.findIfIsThemeModeEnabled(),
    themeMode: ThemeModeUtils.getThemeModeOrDefault(),
    themeModeSettings: ThemeModeUtils.getThemeModeSettings(),
    orientation: null,
    isPopupVisible: false,
    noScrolls: []
  },
  userContext: {
    bonusBalance: "",
    brandId: undefined,
    cid: undefined,
    culture: "",
    currency: "",
    deviceType: UIUtils.getWidth() > WIDTH_TRANSITION_VALUE ? "PC" : "Mobile",
    firstName: "",
    isAuthenticated: false,
    isNativeIOS: false,
    isMobileWebApp: false,
    isVip: false,
    lastLogin: "",
    lastName: "",
    miniCashierDetails: undefined,
    miniCashierEnabled: false,
    nativeUXVersion: NativeUtils.getNativeUXVersion(),
    oddsFormat: "",
    realBR: "",
    RMS: null,
    sessionTime: undefined,
    showBonusBalance: false,
    subBrandId: undefined,
    userTickets: {
      tickets: {},
      readTicketIds: []
    },
    userBalance: "",
    userCountry: "",
    username: "",
    oddsFormatSettings: window.sportClientDataProvider?.odds_format || [],
    iconUrls: window.sportClientDataProvider?.icon_urls || [],
    isIOS: isIOS,
    isPromotionRestricted: false
  },
  native: {
    isTouchIdCanBeUsed: false,
    isBiometricsFlowEnabled: false,
    isBiometricsEnrolled: false
  },
  settings: {
    general: {
      isDrawerActive: false,
      isDrawerLoaded: false
    },
    odds: {
      current: null,
      settings: window.sportClientDataProvider?.odds_format || []
    },
    appearance: {
      current: ThemeModeUtils.getThemeModeOrDefault(),
      settings: ThemeModeUtils.getThemeModeSettings() || []
    },
    language: { title: "", current: null, settings: [] },
    country: { title: "", settings: [] }
    //TO DO - move biometrics from native
    //biometrics: { isBiometricsFlowEnabled: false, isBiometricsEnrolled: false }
  },
  feature: {
    activeFeatures: [],
    abTestActiveFeatures: []
  }
};
