export class UIUtils {
  static getWidth = () => window.innerWidth;

  static setHtmlThemeMode = (themeModes, setThemeMode) => {
    if (setThemeMode) {
      const themeModeClass = UIUtils.getThemeModeClass(setThemeMode);

      themeModes?.forEach(themeMode => {
        const removeThemeModeClass = UIUtils.getThemeModeClass(themeMode);

        if (removeThemeModeClass)
          document.body.classList.remove(removeThemeModeClass);
      });

      document.body.classList.add(themeModeClass);
    }
  };

  static getThemeModeClass = themeMode => {
    if (themeMode) {
      const themeModeClass = `theme-mode-${themeMode.name}`;

      return themeModeClass.toLowerCase();
    }

    return null;
  };

  static isClientMode = () => {
    const SportOffersLinkID = "20";

    let isClientMode = window.IsClientMode ?? false;
    if (!isClientMode) {
      const currentSearchParams = new URLSearchParams(window.location.search);
      isClientMode = currentSearchParams.get("LinkId") === SportOffersLinkID;
      window.IsClientMode = isClientMode;
    }

    return isClientMode;
  };
}
