import { FeatureActionConstants } from "../action-constants";

const setActiveFeatures = activeFeatures => ({
  type: FeatureActionConstants.SET_ACTIVE_FEATURES,
  payload: activeFeatures
});

const setABTestActiveFeatures = abTestActiveFeatures => ({
  type: FeatureActionConstants.SET_ABTEST_ACTIVE_FEATURES,
  payload: abTestActiveFeatures
});

export default {
  setActiveFeatures,
  setABTestActiveFeatures
};
