import { PageContextUtils } from "../../utils";
import Loadable from "react-loadable";
import { EmptyComponent } from "../../components/common/EmptyComponent";

export const CrossActivity = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "crossactivity/pc/crossActivity" */ "./CrossActivity.pc"
    ),
  loading: EmptyComponent
});

export const MobileCrossActivity = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "crossactivity/mobile/crossActivity" */ "./CrossActivity.mobile"
    ),
  loading: EmptyComponent
});

export const getCrossSellPC = async (brandId, clientProvider) => {
  try {
    const { getCrossSell } = await import(
      /* webpackChunkName: "[request]" */
      `../Brands/${brandId}/${clientProvider}/CrossActivity.resource`
    );

    return getCrossSell;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const getCrossSellMobile = async (brandId, clientProvider) => {
  try {
    const { getCrossSell } = await import(
      /* webpackChunkName: "[request]" */
      `../Brands/${brandId}/${clientProvider}/MobileCrossActivity.resource`
    );

    return getCrossSell;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const preloadResources = async isPC => {
  const brandId = window.__UAA_STATE__?.userContext.brandId;
  const { name } = PageContextUtils.getClientProviderData();

  if (isPC) {
    getCrossSellPC(brandId, name);
  } else {
    getCrossSellMobile(brandId, name);
  }
};
