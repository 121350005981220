import ExternalUtils from "./externalServiceUtils";
import { LocalStorageUtils } from "./localStorageUtils";
import { SessionStorageUtils } from "./sessionStorageUtils";

export class NativeUtils {
  static findIfIsNative = () => {
    const isAndroidNative =
      typeof window["WrapperInterface"] === "object" &&
      typeof window["NativeInterface"] !== "undefined";

    if (isAndroidNative) return true;

    const isiOSNative =
      typeof window["NativeInterface"] !== "undefined" &&
      typeof window["webkit"] === "object" &&
      typeof window["webkit"].messageHandlers.callbackHandler === "object";
    if (isiOSNative) return true;

    const nativeUXVersion = NativeUtils.getNativeUXVersion();
    if (nativeUXVersion > 0) return true;

    const nativeDataStore = SessionStorageUtils.get("NativeDataStore");

    return nativeDataStore !== undefined && nativeDataStore !== null;
  };

  static getNativeUXVersion = function () {
    const localNativeUXVersion = LocalStorageUtils.get("nativeUXVersion");

    return localNativeUXVersion === null ? 0 : parseInt(localNativeUXVersion);
  };

  static clearNativeDataStore = async () => {
    if (NativeUtils.findIfIsNative()) {
      await ExternalUtils.UCSDKService.clearNativeDataStore();
    }
  };

  static updateNativeDataStore = async () => {
    if (NativeUtils.findIfIsNative()) {
      await ExternalUtils.UCSDKService.updateNativeDataStore();
    }
  };
}
