import { Channel, SessionStorageItems, Topic } from "../constants";
import { useCallback, useEffect } from "react";

import { MessageBrokerUtils } from "../utils";
import { UIActionCreators } from "../redux/action-creators";
import { useDispatch } from "react-redux";

export const useTouchIdSettingsSubscription = () => {
  const dispatch = useDispatch();

  const setTouchIdVisible = useCallback(
    data => dispatch(UIActionCreators.setTouchIdVisible(data)),
    [dispatch]
  );

  const setTouchIdData = useCallback(
    data => dispatch(UIActionCreators.setTouchIdData(data)),
    [dispatch]
  );

  useEffect(() => {
    const openTouchidSettingsSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.UI,
      topic: Topic.OpenTouchidSettingsPage,
      callback: data => {
        setTouchIdData({
          enrollmentMode: data?.enrollmentMode || false,
          callbackUrl: data?.callbackUrl
        });
        setTouchIdVisible(true);
      }
    });

    return () => {
      openTouchidSettingsSubscription?.unsubscribe();
    };
  }, [setTouchIdData, setTouchIdVisible]);
};

export const useTouchIdSettingsAutoLogin = () => {
  const dispatch = useDispatch();

  const setTouchIdVisible = useCallback(
    data => dispatch(UIActionCreators.setTouchIdVisible(data)),
    [dispatch]
  );

  useEffect(() => {
    const isTouchIdAutoLogin = sessionStorage.getItem(
      SessionStorageItems.IsTouchIdAutoLogin
    );

    sessionStorage.removeItem(SessionStorageItems.IsTouchIdAutoLogin);

    if (isTouchIdAutoLogin === "true") {
      setTouchIdVisible(true);
    }
  }, [setTouchIdVisible]);
};
