import {
  PageContextUtils,
  LocalStorageUtils,
  UrlUtils,
  NativeUtils,
  AppFeatureUtils
} from ".";
import { AppFeatures } from "../constants/featureFlags";

export class ThemeModeUtils {
  static findIfIsThemeModeEnabled = () => {
    const isThemeModeEnabled = AppFeatureUtils.findIfIsInFeature(
      AppFeatures.THEME_MODE
    );

    return isThemeModeEnabled;
  };

  static getThemeModeSettings = () => {
    const { modes } = PageContextUtils.getThemeData();

    return modes;
  };

  static parseThemeMode = themeModeCode => {
    const themeModeSettings = ThemeModeUtils.getThemeModeSettings();

    const themeMode = themeModeSettings?.find(
      modeSetting => modeSetting.code === themeModeCode
    );

    return themeMode;
  };

  static getThemeMode = () => {
    return LocalStorageUtils.get("ThemeMode");
  };

  static getNativeSystemThemeMode = () => {
    let themeMode = null;

    const queryStringThemeModeCode =
      UrlUtils.getQueryStringParameterByName("themeMode");

    if (queryStringThemeModeCode) {
      const themeModeCode = parseInt(queryStringThemeModeCode);

      themeMode = ThemeModeUtils.parseThemeMode(themeModeCode);
    }

    return themeMode;
  };

  static getNativeThemeMode = () => {
    return LocalStorageUtils.get("NativeThemeMode");
  };

  static getDefaultThemeMode = () => {
    return PageContextUtils.getThemeData().defaultMode;
  };

  static getThemeModeOrDefault = () => {
    const getThemeModeArray = [];
    getThemeModeArray.push(ThemeModeUtils.getThemeMode);

    const isNative = NativeUtils.findIfIsNative();
    if (isNative) {
      getThemeModeArray.push(ThemeModeUtils.getNativeSystemThemeMode);
      getThemeModeArray.push(ThemeModeUtils.getNativeThemeMode);
    }

    getThemeModeArray.push(ThemeModeUtils.getDefaultThemeMode);

    let themeMode = null;

    for (let i = 0; i < getThemeModeArray.length; i++) {
      const getThemeMode = getThemeModeArray[i];

      themeMode = getThemeMode();

      if (themeMode) break;
    }

    return themeMode;
  };
}
