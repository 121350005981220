import { useEffect, useState, useCallback } from "react";

/**
 * responsible for returning a watchable variable for detecting
 * if a none react element exists on the dom
 * @param props
 * @returns {boolean}
 */
export const useNonReactElementExist = props => {
  const { parentElement = "body", elementToWatch } = props;

  const checkIFMyCompExists = useCallback(() => {
    return !!document.querySelector(elementToWatch);
  }, [elementToWatch]);

  const [elementExists, setElementExist] = useState(checkIFMyCompExists());
  const [indicator, setIndicator] = useState(false);

  const observeTarget = target => callback => {
    const mutationObserver = new MutationObserver(callback);
    mutationObserver.observe(target, { childList: true });
  };

  const observeProductTourOverlay = observeTarget(
    document.querySelector(parentElement)
  );

  useEffect(() => {
    observeProductTourOverlay(() => {
      const doesExist = checkIFMyCompExists();
      setElementExist(doesExist);
      // this will fire every time something inside container changes
      // (i.e. a child is added or removed)
    });
    // garbage collector should take care of mutationObserver in a way there are no memory leaks, so no need to disconnect it on compoment unmouting.
  }, [checkIFMyCompExists, observeProductTourOverlay]);

  useEffect(() => {
    setIndicator(elementExists);
  }, [elementExists]);

  return indicator;
};
