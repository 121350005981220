import { Channel, Topic } from "../constants";

import { MessageBrokerUtils } from "../utils";
import { UserContextActionCreators } from "../redux/action-creators";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export const useBalanceUpdates = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const balanceUpdate = data => {
      console.log(
        `BalanceUpdated event catched, new balance is: ${data.Balance}`
      );

      dispatch(UserContextActionCreators.updateBalance(data.Balance));
    };

    const balanceSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.WALLET,
      topic: Topic.BalanceUpdated,
      callback: balanceUpdate
    });

    return () => {
      balanceSubscription?.unsubscribe();
    };
  }, [dispatch]);
};
