export default {
  GENERAL: {
    TOGGLE_DRAWER: "settings/general/TOGGLE_DRAWER",
    SET_DRAWER_LOADED: "settings/general/SET_DRAWER_LOADED"
  },
  ODDS: {
    SET_CURRENT: "settings/odds/SET_CURRENT"
  },
  APPEARANCE: {
    SET_CURRENT: "settings/appearance/SET_CURRENT"
  },
  LANGUAGE: {
    SET_TITLE: "settings/language/SET_TITLE",
    SET_CURRENT: "settings/language/SET_CURRENT",
    SET_SETTINGS: "settings/language/SET_SETTINGS"
  },
  COUNTRY: {
    SET_TITLE: "settings/country/SET_TITLE",
    SET_CURRENT: "settings/country/SET_CURRENT",
    SET_SETTINGS: "settings/country/SET_SETTINGS"
  }
};
