import { Channel, Topic } from "../../../constants";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MenuActionCreators } from "../../../redux/action-creators";
import { MenuSelectors } from "../../../redux/selectors";
import { MessageBrokerUtils } from "../../../utils";
import PropTypes from "prop-types";
import { TrackingUtils } from "../../../utils";
import classNames from "classnames";
import { SVGIcon2 } from "../../common/SVGIconV2";
import { MenuSpinner } from "../../SVG/MenuSpinner";

export const MenuFavourite = ({
  isSelected,
  id,
  onError,
  isChildItem,
  sport
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();
  const favouriteSportsSelectable = useSelector(
    MenuSelectors.favouriteSportsSelectable
  );

  useEffect(() => {
    setIsActive(isSelected);
  }, [isSelected]);

  const toggleFavouriteSelected = isSelected => {
    dispatch(MenuActionCreators.setFavouriteSportsSelectable(false));
    setIsUpdating(true);
    const favouriteSportsSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.PLAYER,
      topic: Topic.favouriteSports,
      callback: data => {
        if (data.error_msg) {
          onError(data.error_msg);
        } else {
          setIsActive(isSelected);
          TrackingUtils.sendAnalytics({
            dataCategory: "favourite-sports",
            dataAction: `side-nav-${
              isChildItem ? "az-sports" : "menu-content"
            }`,
            dataLabel: `${sport}-${isSelected ? "select" : "deselect"}`,
            dataValue: ""
          });
        }
        dispatch(MenuActionCreators.setFavouriteSportsSelectable(true));
        setIsUpdating(false);
        favouriteSportsSubscription.unsubscribe();
      }
    });

    MessageBrokerUtils.publish({
      channel: Channel.PLAYER,
      topic: Topic.favouriteUpdate,
      headers: { publisher: "UAA" },
      data: { sport: parseInt(id), status: isSelected }
    });
  };

  const handleClick = e => {
    if (favouriteSportsSelectable) toggleFavouriteSelected(!isActive);
    if (isChildItem) {
      dispatch(
        MenuActionCreators.setFavouriteSportsRunningInsideInnerMenu(true)
      );
    }
    e.preventDefault();
    e.stopPropagation();
    return true;
  };

  const menuFavouriteClasses = useMemo(
    () => classNames("menu-right-icon", "favourite_active"),
    []
  );

  return (
    <span
      onClick={handleClick}
      className={isActive ? menuFavouriteClasses : "menu-right-icon"}
      data-navigation-ignore={true}
    >
      {!isUpdating && isActive && <SVGIcon2 className="star" iconName="star" />}
      {!isUpdating && !isActive && (
        <SVGIcon2 className="star-empty" iconName="star-empty" />
      )}
      {isUpdating && <MenuSpinner />}
    </span>
  );
};

MenuFavourite.propTypes = {
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  onError: PropTypes.func,
  isChildItem: PropTypes.bool,
  sport: PropTypes.string
};
