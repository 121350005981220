import { Channel, Topic } from "../constants";

import { MessageBrokerUtils } from "../utils";
import { UIActionCreators } from "../redux/action-creators";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export const useClientInitialization = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const subscription = MessageBrokerUtils.subscribe({
      channel: Channel.SESSION,
      topic: Topic.clientInitSucceeded,
      callback: () => {
        dispatch(UIActionCreators.setClientInitialized());
        subscription?.unsubscribe();
      }
    });
  }, [dispatch]);
};
