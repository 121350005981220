import { Channel, Topic } from "../constants";
import { useDispatch, useSelector } from "react-redux";

import { MenuActionCreators } from "../redux/action-creators";
import { MenuSelectors } from "../redux/selectors";
import { MessageBrokerUtils } from "../utils";
import { useEffect } from "react";

export const useFavouriteSports = () => {
  const sportCategories = useSelector(MenuSelectors.sportCategories);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleFavouriteSports = data => {
      const favouriteSports = [];
      if (data.favourite_sports.length > 0) {
        const sportsAZ = sportCategories[sportCategories.length - 1];
        const sportsQuickLinks = sportCategories.slice(
          0,
          sportCategories.length - 1
        );
        data.favourite_sports.forEach(fs => {
          let sportFound;
          sportFound = sportsAZ.items.find(
            item => parseInt(item.sportID) === fs
          );
          if (!sportFound) {
            sportFound = sportsQuickLinks.find(
              item => parseInt(item.sportID) === fs
            );
          }
          favouriteSports.push(sportFound);
        });
      }

      dispatch(MenuActionCreators.setFavouriteSports(favouriteSports));
    };

    const favouriteSportsSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.PLAYER,
      topic: Topic.favouriteSports,
      callback: handleFavouriteSports
    });

    return () => {
      favouriteSportsSubscription?.unsubscribe();
    };
  }, [dispatch, sportCategories]);
};
