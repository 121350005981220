import { CategoriesIconsSvgId } from "../constants";
import IconApi from "../redux/api/iconApi";

class IconManager {
  constructor() {
    // eslint-disable-next-line no-undef
    this.iconsReady = new DeferredObject();
    this.processing = false;
  }

  processClientIcons = async () => {
    if (!this.processing && !this.iconsReady.resolved) {
      this.processing = true;
      let hasIcons = false;
      const iconUrls = window.sportClientDataProvider?.icon_urls || [];
      if (iconUrls.length) {
        const responses = await Promise.all(iconUrls.map(this._downloadIcons));
        const successfullResponses = responses
          .map(elem => elem.response)
          .filter(Boolean);

        hasIcons = successfullResponses.length;

        if (hasIcons) {
          this._addIconsToDOM(successfullResponses);
        }

        this.processing = false;
      }

      this.iconsReady.resolve(hasIcons);
    }
  };

  _downloadIcons = url => IconApi.icon(url);
  _addIconsToDOM = iconsList => {
    let svgDiv = document.createElement("div");
    svgDiv.setAttribute("id", CategoriesIconsSvgId);
    svgDiv.style.setProperty("display", "none");
    svgDiv.innerHTML = iconsList.reduce((elem, acc) => [elem, acc].join(""));
    document.body.appendChild(svgDiv);
  };
}

export const { processClientIcons, iconsReady } = new IconManager();
