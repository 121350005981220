import { UIActionConstants } from "../action-constants";

const setClientInitialized = () => ({
  type: UIActionConstants.CLIENT_INITIALIZED
});

const setLinks = links => ({
  type: UIActionConstants.SET_LINKS,
  payload: links
});

const setConfig = config => ({
  type: UIActionConstants.SET_CONFIG,
  payload: config
});

const updateRightSideDrawer = payload => ({
  type: UIActionConstants.UPDATE_RIGHT_SIDE_DRAWER,
  payload
});

const addElementRightSideDrawer = payload => ({
  type: UIActionConstants.ADD_ELEMENT_RIGHT_SIDE_DRAWER,
  payload
});

const removeElementRightSideDrawer = payload => ({
  type: UIActionConstants.REMOVE_ELEMENT_RIGHT_SIDE_DRAWER,
  payload
});

const toggleMainViewApp = payload => ({
  type: UIActionConstants.TOGGLE_MAIN_VIEW_APP,
  payload
});

const setScreenType = screenType => ({
  type: UIActionConstants.SET_SCREEN_TYPE,
  payload: screenType
});

const setTouchIdVisible = isVisible => ({
  type: UIActionConstants.SET_TOUCHID_VISIBLE,
  payload: isVisible
});

const setTouchIdData = data => ({
  type: UIActionConstants.SET_TOUCHID_DATA,
  payload: data
});

const createFullScreenPopup = payload => ({
  type: UIActionConstants.CREATE_FULL_SCREEN_POPUP,
  payload
});

const closePanicTooltip = isClosed => ({
  type: UIActionConstants.CLOSE_PANIC_TOOLTIP,
  payload: isClosed
});

const setThemeMode = mode => ({
  type: UIActionConstants.SET_THEME_MODE,
  payload: mode
});

const userInteracted = () => ({ type: UIActionConstants.USER_INTERACTED });

const toggleBubble = payload => ({
  type: UIActionConstants.TOGGLE_BUBBLE,
  payload
});

const setLastBubblePosition = payload => ({
  type: UIActionConstants.SET_LAST_BUBBLE_POSITION,
  payload
});

const setDeviceOrientation = payload => ({
  type: UIActionConstants.SET_DEVICE_ORIENTATION,
  payload
});

const disablePopups = payload => ({
  type: UIActionConstants.DISABLE_POPUPS,
  payload
});

const renderControlCenterMini = payload => ({
  type: UIActionConstants.RENDER_CONTROL_CENTER_MINI,
  payload
});

const setPopupVisibility = payload => ({
  type: UIActionConstants.SET_POPUP_VISIBILITY,
  payload
});

const setNoScroll = payload => ({
  type: UIActionConstants.SET_NO_SCROLL,
  payload
});

export default {
  addElementRightSideDrawer,
  closePanicTooltip,
  createFullScreenPopup,
  disablePopups,
  removeElementRightSideDrawer,
  setClientInitialized,
  setConfig,
  setDeviceOrientation,
  setLastBubblePosition,
  setLinks,
  setScreenType,
  setThemeMode,
  setTouchIdData,
  setTouchIdVisible,
  toggleBubble,
  toggleMainViewApp,
  updateRightSideDrawer,
  userInteracted,
  renderControlCenterMini,
  setPopupVisibility,
  setNoScroll
};
