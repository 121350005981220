import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MessageBrokerUtils } from "../utils";
import { Channel, Topic } from "../constants";
import { UserContextSelectors } from "../redux/selectors";
import { UIActionCreators } from "../redux/action-creators";

export const useScroll = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(UserContextSelectors.isMobile);

  useEffect(() => {
    const setNoScroll = (data = {}) => {
      const { identifier, isDisabled, selector } = data;

      const noScrollSelector = selector
        ? selector
        : isMobile
          ? "body"
          : ".uc-content-area";

      dispatch(
        UIActionCreators.setNoScroll({
          id: identifier || "onDemand",
          selector: noScrollSelector,
          isNoScroll: isDisabled,
          options: { noScrollClass: "no-scroll" }
        })
      );
    };

    const scrollToTop = (data = {}) => {
      if (!isMobile) {
        const { selector = ".uc-content-area" } = data;

        const element = getElement(selector);
        if (element) element.scrollTo(0, 0);
      } else window.scrollTo(0, 0);
    };

    const noScrollSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.UI,
      topic: Topic.scrollActivation,
      callback: setNoScroll
    });

    const scrollToTopSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.UI,
      topic: Topic.scrollToTop,
      callback: scrollToTop
    });

    const navigationSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.ROUTER,
      topic: Topic.Navigate,
      callback: scrollToTop
    });

    return () => {
      noScrollSubscription?.unsubscribe();
      scrollToTopSubscription?.unsubscribe();
      navigationSubscription?.unsubscribe();
    };
  }, [isMobile, dispatch]);

  const getElement = selector => {
    return typeof selector === "object"
      ? selector
      : document.querySelector(selector);
  };
};
