import { Channel, Topic } from "../constants";

import { MessageBrokerUtils } from "../utils";
import { useCallback } from "react";

export const useNativeCommunicationHandlers = () => {
  const openBiometricSettings = useCallback(data => {
    MessageBrokerUtils.publish({
      channel: Channel.BIOMETRICS,
      topic: Topic.biometricSettings,
      headers: { publisher: "UAA" },
      data: data
    });
  }, []);

  return {
    openBiometricSettings
  };
};
