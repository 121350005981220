import { PageContextUtils } from "../../utils";
import { Theme } from "../../constants";

const { environment } = PageContextUtils.getEnvironmentData();

const isLocal = environment?.toLowerCase() === "local";

if (isLocal) {
  const { currentName = Theme.Default } = PageContextUtils.getThemeData();

  switch (currentName) {
    case Theme.SportsIllustrated: {
      import(
        /* webpackChunkName: "theme/sportsIllustrated" */
        "./sportsIllustrated.scss"
      );
      break;
    }
    case Theme.Poker: {
      import(
        /* webpackChunkName: "theme/poker" */
        "./poker.scss"
      );
      break;
    }
    case Theme.MrGreen: {
      import(
        /* webpackChunkName: "theme/mrGreen" */
        "./mrGreen.scss"
      );

      break;
    }
    case Theme.Default:
    default: {
      import(
        /* webpackChunkName: "theme/default" */
        "./default.scss"
      );
    }
  }
}
