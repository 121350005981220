import { useDispatch, useSelector } from "react-redux";

import { UIActionCreators } from "../redux/action-creators";
import { UserContextSelectors } from "../redux/selectors";
import { useEffect } from "react";
import { PageContextUtils } from "../utils";

const getConfig = async brandId => {
  const { name } = PageContextUtils.getClientProviderData();

  try {
    const { links, config } = await import(
      /* webpackChunkName: "[request]" */
      `../segmentedComponents/Brands/${brandId}/${name}/Config.resource.js`
    );

    return { links, config };
  } catch (e) {
    console.error("[Config] Cannot load Config resources");
    console.error(e);
  }

  return {};
};

const defaultConfig = {
  gameHeaderIcons: ["game-frame-eighteenplus"]
};

const preloadConfig = async () => {
  const brandId = window.__UAA_STATE__?.userContext.brandId;
  return await getConfig(brandId);
};

export const ConfigPromise = preloadConfig();

export const useConfig = () => {
  const brandId = useSelector(UserContextSelectors.brandId);
  const dispatch = useDispatch();

  useEffect(() => {
    const gatherConfig = async () => {
      const configResource = await ConfigPromise;
      dispatch(UIActionCreators.setLinks(configResource?.links || {}));
      dispatch(
        UIActionCreators.setConfig(configResource?.config || defaultConfig)
      );
    };

    gatherConfig();
  }, [brandId, dispatch]);
};
