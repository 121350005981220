const languageMapping = new Map([["br", "pt"]]);

export class LocalizationUtils {
  static getByBrandId = (key, brandId) => `${key}_${brandId}`;

  static getByCode = (key, code) => {
    return `${key}${code}`;
  };

  static getMappedLanguage = lang => {
    return languageMapping.has(lang) ? languageMapping.get(lang) : lang;
  };
}
