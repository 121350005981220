import { useEffect, useMemo, useRef, useState } from "react";

//https://marmelab.com/blog/2023/01/11/use-async-effect-react.html
export const useAsyncEffect = (sideEffect, cleanupSideEffect, dependencies) => {
  const isAsyncEffectRunning = useRef(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [result, setResult] = useState();

  useEffect(() => {
    isAsyncEffectRunning.current = true;

    return () => (isAsyncEffectRunning.current = false);
  }, []);

  useEffect(() => {
    let isIgnored = false;
    let isRunning = false;

    (async () => {
      // wait for the initial cleanup in Strict mode - avoids double mutation
      await Promise.resolve();

      if (!isAsyncEffectRunning.current || isIgnored) return;

      setIsLoading(true);

      try {
        const result = await sideEffect();

        isRunning = true;

        if (isAsyncEffectRunning.current && !isIgnored) {
          setError(undefined);
          setResult(result);
          setIsLoading(false);
        } else {
          // Component was unmounted before the mount callback returned, cancel it
          cleanupSideEffect();
        }
      } catch (error) {
        if (!isAsyncEffectRunning.current) return;

        setError(error);
        setIsLoading(false);
      }
    })();

    return () => {
      isIgnored = true;

      if (isRunning) {
        const onCleanupDone = () => {
          if (!isAsyncEffectRunning.current) return;

          setResult(undefined);
        };

        if (cleanupSideEffect) {
          const onCleanupError = error => {
            if (!isAsyncEffectRunning.current) return;

            setError(error);
          };

          cleanupSideEffect().then(onCleanupDone).catch(onCleanupError);
        } else onCleanupDone();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return useMemo(
    () => ({ result, error, isLoading }),
    [result, error, isLoading]
  );
};
