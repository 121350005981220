import { GlobalActionConstants } from "../action-constants";

const { INIT_STATE, UPDATE_STATE } = GlobalActionConstants;
const initState = newState => ({
  type: INIT_STATE,
  payload: newState
});

const updateState = newState => ({
  type: UPDATE_STATE,
  payload: newState
});

export default {
  initState,
  updateState
};
