import { StorageDataStore } from "../store";

const storageNativeDataStore = new StorageDataStore(
  sessionStorage,
  "NativeDataStore"
);

const getNativeDataStore = () => {
  const store = storageNativeDataStore.get();

  return store ? JSON.parse(store) : {};
};

const getNativeDataStoreItem = item => {
  const store = storageNativeDataStore.get();

  return store && JSON.parse(store)[item];
};

export default {
  getNativeDataStore,
  getNativeDataStoreItem
};
