import { Channel, Topic } from "../constants";

import { MessageBrokerUtils } from "../utils";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { MenuActionCreators } from "../redux/action-creators";
import { useSelector } from "react-redux";
import { MenuSelectors, UISelectors } from "../redux/selectors";

export const useToggleAZSports = () => {
  const userInteracted = useSelector(UISelectors.userInteracted);
  const dispatch = useDispatch();
  const quickLinksInnerMenuVisible = useSelector(
    MenuSelectors.quickLinksInnerMenuVisible
  );
  const drawersLoaded = useSelector(MenuSelectors.drawersLoaded);
  const [delayedShowAZSportsMenu, setDelayedShowAZSportsMenu] = useState(false);

  const ToggleAZSports = useCallback(() => {
    if (quickLinksInnerMenuVisible) {
      dispatch(MenuActionCreators.hideQuickLinksInnerMenu());
    } else if (drawersLoaded && !quickLinksInnerMenuVisible) {
      dispatch(MenuActionCreators.showQuickLinksInnerMenu());
    } else {
      setDelayedShowAZSportsMenu(true);
    }
  }, [dispatch, drawersLoaded, quickLinksInnerMenuVisible]);

  useEffect(() => {
    if (delayedShowAZSportsMenu && drawersLoaded) {
      dispatch(MenuActionCreators.showQuickLinksInnerMenu());
    }
  }, [delayedShowAZSportsMenu, dispatch, drawersLoaded]);

  useEffect(() => {
    const toggleAZSportsSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.USER_INTERACTION,
      topic: Topic.azSportsToggle,
      callback: ToggleAZSports
    });

    return () => {
      toggleAZSportsSubscription?.unsubscribe();
    };
  }, [dispatch, userInteracted, quickLinksInnerMenuVisible, ToggleAZSports]);
};
