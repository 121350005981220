import { FormattedMessage } from "react-intl";
import { HighlightedText } from "./Components";
import { MenuItem } from ".";
import PropTypes from "prop-types";
import React from "react";

export const HighlightedMenuItem = ({ highlightedText, ...restProps }) => {
  return (
    <MenuItem
      {...restProps}
      content={
        <>
          <HighlightedText text={highlightedText} />
          <FormattedMessage {...restProps?.item.title} />
        </>
      }
    />
  );
};

HighlightedMenuItem.propTypes = {
  highlightedText: PropTypes.object
};
