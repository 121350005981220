import PropTypes from "prop-types";
import React from "react";
import { useSvgLoader } from "../../../hooks";
import { SVGIcon2 } from "../../common/SVGIconV2";

export const MenuIcon = ({
  iconURL,
  iconKey,
  containerClass,
  asBackground
}) => {
  const loadedIcons = useSvgLoader();

  if (iconKey && iconKey.startsWith("#")) {
    return (
      <i>
        {loadedIcons ? (
          <svg>
            <use href={iconKey} xlinkHref={iconKey} />
          </svg>
        ) : (
          <SVGIcon2 iconName="default-icon" />
        )}
      </i>
    );
  }
  const iconKeyWithFallback = iconKey || "default-icon";

  if (iconURL && !iconKey) {
    return (
      <i>
        <img src={iconURL} alt={iconKeyWithFallback}></img>
      </i>
    );
  }

  if (containerClass)
    return (
      <span className={containerClass}>
        <i>
          <SVGIcon2
            iconName={iconKeyWithFallback}
            asBackground={asBackground}
          />
        </i>
      </span>
    );

  return (
    <i>
      <SVGIcon2 iconName={iconKeyWithFallback} asBackground={asBackground} />
    </i>
  );
};

MenuIcon.defaultProps = {
  containerClass: null,
  asBackground: false
};

MenuIcon.propTypes = {
  iconKey: PropTypes.string,
  iconURL: PropTypes.string,
  containerClass: PropTypes.string,
  asBackground: PropTypes.bool
};
