import { OverlayActionConstants } from "../action-constants";

const defaultOverlayOptions = { identifier: "defaultOverlay" };

const computeOptions = options => {
  const computedOptions = options || defaultOverlayOptions;

  if (!computedOptions.identifier)
    computedOptions.identifier = defaultOverlayOptions.identifier;

  return computedOptions;
};

const show = options => {
  const computedOptions = computeOptions(options);

  return {
    type: OverlayActionConstants.SHOW,
    payload: { options: computedOptions }
  };
};

const hide = options => {
  const computedOptions = computeOptions(options);

  return {
    type: OverlayActionConstants.HIDE,
    payload: { options: computedOptions }
  };
};

export default {
  show,
  hide
};
