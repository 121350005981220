export class SessionStorageUtils {
  static get(key) {
    if (!key) return undefined;

    return sessionStorage.getItem(key);
  }

  static set(key, value) {
    if (!key) return;

    sessionStorage.setItem(key, value);
  }

  static remove(key) {
    if (!key) return;

    sessionStorage.removeItem(key);
  }

  static clear() {
    sessionStorage.clear();
  }
}
