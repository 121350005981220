import { Channel, Topic } from "../constants";
import { MessageBrokerUtils, PageContextUtils } from "../utils";
import { useCallback, useEffect } from "react";

import { UserContextActionCreators } from "../redux/action-creators";
import { useDispatch } from "react-redux";

export const useIsMobile = () => {
  const dispatch = useDispatch();

  const updateIsMobile = useCallback(
    deviceInformation => {
      const isMobileWebApp =
        !!deviceInformation &&
        deviceInformation.isMobile &&
        !deviceInformation.isHybrid;
      const isMobileDevice = !!deviceInformation && deviceInformation.isMobile;

      dispatch(UserContextActionCreators.updateIsMobileWebApp(isMobileWebApp));
      dispatch(UserContextActionCreators.updateIsMobileDevice(isMobileDevice));
    },
    [dispatch]
  );

  useEffect(() => {
    const dataStoreDeviceInformation = PageContextUtils.getDeviceData();

    updateIsMobile(dataStoreDeviceInformation);
  }, [updateIsMobile]);

  useEffect(() => {
    const isMobileWebAppSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.UI,
      topic: Topic.DeviceInformation,
      callback: updateIsMobile
    });

    return () => {
      isMobileWebAppSubscription?.unsubscribe();
    };
  }, [updateIsMobile]);
};
