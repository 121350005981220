import { Channel, Topic } from "../constants";

import { MessageBrokerUtils } from "../utils";

export class TrackingUtils {
  static sendEvent = eventData => {
    MessageBrokerUtils.publish({
      channel: Channel.TRACKING,
      topic: Topic.sendEvent,
      headers: { publisher: "UAA" },
      data: eventData
    });
  };

  static sendAnalytics = (eventData, publisher = "UAA") => {
    MessageBrokerUtils.publish({
      channel: Channel.TRACKING,
      topic: Topic.sendAnalytics,
      headers: { publisher },
      data: eventData
    });
  };
}

export class B2CEventTracker {
  static sendEvent = eventData => {
    if (!window.dataLayer) {
      console.error("Data layer does not exist!");

      return;
    }

    if (eventData) {
      const loggedEvent = {
        label: undefined,
        ...eventData,
        local_time: new Date().toString()
      };
      window.dataLayer?.push(loggedEvent);

      console.table(loggedEvent);
    } else console.warn("Nothing to log.");
  };
}
