import { LanguageSwitcherActionConstants } from "../action-constants";

const toggleDropDown = () => ({
  type: LanguageSwitcherActionConstants.TOGGLE_DROP_DOWN
});

const closeMenu = () => ({
  type: LanguageSwitcherActionConstants.CLOSE_MENU
});

const setData = data => ({
  type: LanguageSwitcherActionConstants.SET_DATA,
  payload: data
});

export default {
  closeMenu,
  setData,
  toggleDropDown
};
