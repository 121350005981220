import { SettingsActionConstants } from "../action-constants";

const general = {
  toggleDrawer: () => ({
    type: SettingsActionConstants.GENERAL.TOGGLE_DRAWER
  }),
  setDrawerLoaded: isLoaded => ({
    type: SettingsActionConstants.GENERAL.SET_DRAWER_LOADED,
    payload: isLoaded
  })
};

const odds = {
  setCurrent: oddsSetting => ({
    type: SettingsActionConstants.ODDS.SET_CURRENT,
    payload: oddsSetting
  })
};

const appearance = {
  setCurrent: appearanceSetting => ({
    type: SettingsActionConstants.APPEARANCE.SET_CURRENT,
    payload: appearanceSetting
  })
};

const language = {
  setTitle: title => ({
    type: SettingsActionConstants.LANGUAGE.SET_TITLE,
    payload: title
  }),
  setCurrent: languageSetting => ({
    type: SettingsActionConstants.LANGUAGE.SET_CURRENT,
    payload: languageSetting
  }),
  setSettings: languageSettings => ({
    type: SettingsActionConstants.LANGUAGE.SET_SETTINGS,
    payload: languageSettings
  })
};

const country = {
  setTitle: title => ({
    type: SettingsActionConstants.COUNTRY.SET_TITLE,
    payload: title
  }),
  setSettings: countrySetting => ({
    type: SettingsActionConstants.COUNTRY.SET_SETTINGS,
    payload: countrySetting
  })
};

export default {
  general,
  odds,
  appearance,
  language,
  country
};
