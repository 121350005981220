import { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { Widget } from "./Widget";

export const useWidgetInitialization = () => {
  const dispatch = useDispatch();
  const initTimer = useRef(null);
  const widget = useMemo(() => new Widget(dispatch, initTimer), [dispatch]);

  useEffect(() => {
    widget.handleCreateContainer();
    widget.handleToggleContainer();
    widget.handleError();
    widget.handleDispose();
  }, [dispatch, widget]);
};
