import { Channel, Topic } from "../constants";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { HeaderActionCreators } from "../redux/action-creators";
import { MessageBrokerUtils } from "../utils";

export const useBetDetailsSubscription = () => {
  const dispatch = useDispatch();

  const updateBetsDetails = useCallback(
    ({ NumberOfOpenBets }) => {
      dispatch(HeaderActionCreators.setBetsCount(NumberOfOpenBets));
    },
    [dispatch]
  );

  useEffect(() => {
    let betsCountSubscription;
    betsCountSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.SPORT,
      topic: Topic.betsUpdate,
      callback: updateBetsDetails
    });

    return () => {
      if (betsCountSubscription) {
        console.log("[APP.js] - Unsubscribing from Sport channel...");
        betsCountSubscription?.unsubscribe();
      }
    };
  }, [updateBetsDetails]);
};
