import { EmptyComponent } from "../../common/EmptyComponent";
import Loadable from "react-loadable";

export const ControlCenterMiniLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "control-center/mini" */ "./ControlCenter.mini"
    ),
  loading: EmptyComponent
});

export const ControlCenterHeaderButtonLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "control-center/header-button" */ "./ControlCenter.headerButton"
    ),
  loading: EmptyComponent
});

export const ControlCenterDataLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "control-center/data" */ "./ControlCenter.data"
    ),
  loading: EmptyComponent
});

export * from "./ControlCenter.factory";
