import { Channel, DialogType, OpeningMode, Topic } from "../../../constants";
import { UIActionCreators } from "../../../redux/action-creators";
import { MessageBrokerUtils } from "../../../utils";

export class WidgetErrorService {
  constructor(dispatch) {
    this._dispatch = dispatch;
  }
  handle = () => {
    this._errorSubscription();
    this._interruptionSubscription();
  };

  _errorSubscription = () =>
    MessageBrokerUtils.subscribe({
      channel: Channel.UI,
      topic: Topic.ShowAppError,
      callback: data => {
        const { openingMode } = data;
        switch (openingMode) {
          case OpeningMode.RightSideDrawer:
            this._handleRightSideDrawer(this._dispatch, data);
            break;

          case OpeningMode.FullScreenPopup:
            this._handleFullScreenPopup(this._dispatch, data);
            break;
          case OpeningMode.MainView:
            this._handleMainView(this._dispatch, data);
            break;
          default:
            break;
        }
      }
    });

  _interruptionSubscription = () =>
    MessageBrokerUtils.subscribe({
      channel: Channel.NAVIGATION,
      topic: Topic.actionInterrupted,
      callback: data => {
        const { actionID, actionData, launchInfo, correlationID, errorCode } =
          data || {};

        if (!errorCode) return;

        this._handleTechnicalProblem(
          actionID,
          actionData,
          launchInfo,
          correlationID
        );
      }
    });

  _handleMainView = (dispatch, data) => {
    dispatch(
      UIActionCreators.toggleMainViewApp({
        ...data,
        error: { title: data?.title }
      })
    );
  };

  _handleFullScreenPopup = (dispatch, data) => {
    dispatch(
      UIActionCreators.createFullScreenPopup({
        ...data,
        error: { title: data?.title }
      })
    );
  };

  _handleRightSideDrawer = (dispatch, data) => {
    dispatch(
      UIActionCreators.updateRightSideDrawer({
        ...data,
        error: { title: data?.title },
        isVisible: true,
        isMinimized: false
      })
    );
  };

  _handleTechnicalProblem = (
    actionID,
    actionData,
    launchInfo,
    correlationID
  ) => {
    let retry = null;
    if (actionID) {
      const retryData = {
        actionID,
        correlationID,
        launchInfo,
        actionData
      };

      retry = () => {
        MessageBrokerUtils.publish({
          headers: { publisher: "unifiedclient" },
          channel: Channel.NAVIGATION,
          topic: Topic.performAction,
          data: retryData
        });
      };
    }

    MessageBrokerUtils.publish({
      channel: Channel.UI,
      topic: Topic.Dialog,
      headers: {
        publisher: "UAA"
      },
      data: {
        dialogType: DialogType.TechnicalProblem,
        options: { retry }
      }
    });
  };
}
