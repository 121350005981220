import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuActionCreators } from "../../redux/action-creators";
import PropTypes from "prop-types";
import { SelectableMenuItem } from "./SelectableMenuItem";
import { UserContextSelectors } from "../../redux/selectors";
import { TrackingInfo } from "../tracking/TrackingInfoMenu";

export const HomeSectionMenuItem = props => {
  const { onClick, highlightedText, dataTestId, ...restProps } = props;
  const dispatch = useDispatch();
  const isMobile = useSelector(UserContextSelectors.isMobile);
  const trackingInfo = {
    dataEvent: "UC_GENERIC_EVENT_important",
    ...TrackingInfo
  };
  const handleClick = e => {
    if (typeof onClick === "function" && onClick(e)) {
      return true;
    }

    if (isMobile) {
      dispatch(MenuActionCreators.hideHamburgerMenu());
    }
  };
  return (
    <SelectableMenuItem
      onClick={handleClick}
      trackingInfo={trackingInfo}
      highlightedText={highlightedText}
      dataTestId={dataTestId}
      {...restProps}
    />
  );
};

HomeSectionMenuItem.propTypes = {
  onClick: PropTypes.func,
  dataTestId: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  highlightedText: PropTypes.string
};
