import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { useThemeModeHandler } from "../components/Theme/hooks";
import { Channel, Topic } from "../constants";
import { AppFeatures } from "../constants/featureFlags";
import { UISelectors, UserContextSelectors } from "../redux/selectors";
import { MessageBrokerUtils, ThemeModeUtils } from "../utils";
import { useFeature } from "./useFeature";

export const useThemeMode = () => {
  const isSettingsEnabled = useFeature(AppFeatures.SETTINGS_DRAWER);
  const isNative = useSelector(UserContextSelectors.isNative);
  const isThemeModeEnabled = useSelector(UISelectors.isThemeModeEnabled);
  const themeMode = useSelector(UISelectors.themeMode);
  const { parseThemeMode, setStoreThemeMode, setHtmlThemeMode } =
    useThemeModeHandler();

  useEffect(() => {
    if (!isSettingsEnabled && isThemeModeEnabled && themeMode)
      setHtmlThemeMode(themeMode);
  }, [isSettingsEnabled, isThemeModeEnabled, themeMode, setHtmlThemeMode]);

  const setThemeMode = useCallback(
    themeMode => {
      const parsedThemeMode = parseThemeMode(themeMode);

      if (parsedThemeMode) {
        setStoreThemeMode(parsedThemeMode);
        setHtmlThemeMode(parsedThemeMode);
      }
    },
    [parseThemeMode, setStoreThemeMode, setHtmlThemeMode]
  );

  useEffect(() => {
    let playerPreferencesSubscription = null;

    if (!isSettingsEnabled && isThemeModeEnabled)
      playerPreferencesSubscription = MessageBrokerUtils.subscribe({
        channel: Channel.PLAYER,
        topic: Topic.playerPreferences,
        callback: (data, envelope) => {
          if (envelope?.headers.version === 2) setThemeMode(data?.themeMode);
        }
      });

    return () => {
      playerPreferencesSubscription?.unsubscribe();
    };
  }, [isSettingsEnabled, isThemeModeEnabled, setThemeMode]);

  useEffect(() => {
    let themeModeUpdateSubscription = null;

    if (isSettingsEnabled && isNative)
      themeModeUpdateSubscription = MessageBrokerUtils.subscribe({
        channel: Channel.NATIVE_CHANNEL,
        topic: Topic.themeModeUpdate,
        callback: data => {
          const currentThemeMode = ThemeModeUtils.getThemeMode();
          if (!currentThemeMode) setThemeMode(data?.themeMode);
        }
      });

    return () => {
      themeModeUpdateSubscription?.unsubscribe();
    };
  }, [isSettingsEnabled, isNative, setThemeMode]);
};
