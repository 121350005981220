import React, { useMemo, useState } from "react";
import { MenuArrow, MenuIcon } from "./Components";
import { MenuFavourite } from "./Components/MenuFavourite";
import { MenuTooltip } from "./Components/MenuTooltip";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { TrackingInfo } from "../tracking/TrackingInfoMenu";

export const MenuItem = props => {
  const {
    item,
    trackingInfo,
    hasIcon,
    dataTestId,
    className,
    onClick,
    hasArrow,
    rightIcon,
    arrowText,
    content,
    isActive,
    hasFavouriteFeature,
    isFavourite,
    reference,
    children,
    isChildItem,
    tag,
    ignoreNavigation,
    dataLabel = undefined,
    iconOnly = false
  } = props;

  const [hasFavouriteError, setHasFavouriteError] = useState(false);
  const [favouriteError, setFavouriteError] = useState("");
  const { formatMessage } = useIntl();

  const trackingInfoLocal = {
    dataCategory: "default",
    dataAction: "click",
    ...(trackingInfo ? trackingInfo : TrackingInfo),
    dataLabel: dataLabel || dataTestId
  };

  const { iconKey, iconURL, URL, target, ID, sportID } = item;

  const classes = useMemo(
    () =>
      classNames({
        [className]: !!className,
        item_active: isActive
      }),
    [className, isActive]
  );

  const getContent = () => {
    if (typeof content === "undefined") {
      if (typeof item.title === "object") return formatMessage(item.title);
      return item?.title;
    }
    if (React.isValidElement(content)) return content;
    if (typeof content === "object") return formatMessage(content);

    return content;
  };

  const handleClick = e => {
    if (typeof onClick === "function" && onClick(e)) {
      e.preventDefault();
      e.stopPropagation();
      return true;
    }
  };

  const onErrorTooltipHide = () => {
    setHasFavouriteError("");
    setFavouriteError(null);
  };

  const handleFavouriteError = e => {
    setFavouriteError(e);
    setHasFavouriteError(true);
  };

  const additionalProps = useMemo(() => {
    return ignoreNavigation ? { ["data-navigation-ignore"]: "true" } : null;
  }, [ignoreNavigation]);

  return (
    <li
      ref={reference}
      onClick={handleClick}
      className={classes}
      data-testid={dataTestId}
    >
      <a
        href={URL || "javascript: void(0)"}
        target={URL ? target : ""}
        {...additionalProps}
        data-event={trackingInfoLocal.dataEvent || undefined}
        data-category={trackingInfoLocal.dataCategory}
        data-action={trackingInfoLocal.dataAction}
        data-label={trackingInfoLocal.dataLabel}
        data-value=""
        className={classNames({ ["icon-only"]: iconOnly })}
      >
        {hasIcon && (
          <MenuIcon
            iconKey={iconKey}
            iconURL={iconURL}
            asBackground={iconOnly}
          />
        )}
        {!iconOnly && (
          <>
            <span className="ellipsis-span">{getContent()}</span>
            {rightIcon && (
              <MenuIcon
                asBackground
                iconKey={rightIcon.key}
                iconURL={rightIcon.url}
                containerClass={rightIcon.containerClass}
              />
            )}
            {tag || <></>}
            {hasArrow && <MenuArrow arrowText={arrowText} />}
            {hasFavouriteFeature && (
              <MenuFavourite
                isSelected={isFavourite}
                id={sportID ? sportID : ID}
                onError={handleFavouriteError}
                isChildItem={isChildItem}
                sport={getContent()}
              />
            )}
            {children}
          </>
        )}
      </a>

      {hasFavouriteError && (
        <MenuTooltip
          favouriteError={favouriteError}
          onHide={onErrorTooltipHide}
        />
      )}
    </li>
  );
};

MenuItem.defaultProps = {
  hasIcon: true,
  rightIcon: null
};

MenuItem.propTypes = {
  hasIcon: PropTypes.bool,
  trackingInfo: PropTypes.object,
  dataTestId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  hasArrow: PropTypes.bool,
  rightIcon: PropTypes.object,
  arrowText: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  dataLabel: PropTypes.string,
  item: PropTypes.object,
  isActive: PropTypes.bool,
  hasFavouriteFeature: PropTypes.bool,
  isFavourite: PropTypes.bool,
  reference: PropTypes.object,
  children: PropTypes.node,
  isChildItem: PropTypes.bool,
  tag: PropTypes.node,
  ignoreNavigation: PropTypes.bool,
  iconOnly: PropTypes.bool
};
