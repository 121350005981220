import React, { useMemo } from "react";
import PropTypes from "prop-types";

export const DivIcon = props => {
  const {
    asBackground,
    path,
    iconName,
    dataTestId,
    classNames,
    children,
    ...restProps
  } = props;

  const style = useMemo(() => {
    const iconPath = path && `url(${path})`;
    if (!iconPath) return;

    if (asBackground) {
      return {
        backgroundImage: iconPath,
        backgroundRepeat: "no-repeat",
        backgroundColor: "transparent"
      };
    }

    return {
      WebkitMaskImage: iconPath,
      maskImage: iconPath,
      maskSize: "cover",
      WebkitMaskSize: "cover"
    };
  }, [asBackground, path]);

  return (
    <div className="svg-wrapper">
      <div
        id={iconName}
        style={style}
        className={classNames}
        data-testid={dataTestId}
        {...restProps}
      />
      {children && <>{children}</>}
    </div>
  );
};

DivIcon.propTypes = {
  iconName: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  children: PropTypes.any,
  asBackground: PropTypes.bool,
  path: PropTypes.string,
  classNames: PropTypes.string
};
