import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import FeatureSelectors from "../redux/selectors/featureSelectors";

export const useFeature = (featureName, genericFeatureName) => {
  const activeFeatures = useSelector(FeatureSelectors.activeFeatures);
  const abTestActiveFeatures = useSelector(
    FeatureSelectors.abTestActiveFeatures
  );

  const [isInFeature, setIsInFeature] = useState(false);

  const findIfIsABActiveFeature = useCallback(
    (abFeatureName, genericFeatureName) => {
      if (!abTestActiveFeatures?.length) return false;

      const isActiveFeature = abTestActiveFeatures.some(feature => {
        const featureName = feature?.Name?.toLowerCase();

        if (!featureName) return false;

        return (
          featureName === abFeatureName?.toLowerCase() ||
          featureName === genericFeatureName?.toLowerCase()
        );
      });

      return isActiveFeature;
    },
    [abTestActiveFeatures]
  );

  const findIfIsActiveFeature = useCallback(
    (featureName, genericFeatureName) => {
      if (!activeFeatures?.length) return false;

      const isActiveFeature = activeFeatures.some(feature => {
        const featureId = feature?.id.toString().toLowerCase();
        const featureValue = feature?.value.toString().toLowerCase();

        if (!featureId) return false;

        if (
          featureId === featureName?.toLowerCase() ||
          featureId === genericFeatureName?.toLowerCase()
        )
          return featureValue === "ab"
            ? findIfIsABActiveFeature(featureName, genericFeatureName)
            : true;
      });

      return isActiveFeature;
    },
    [activeFeatures, findIfIsABActiveFeature]
  );

  useEffect(() => {
    let isActiveFeature = findIfIsActiveFeature(
      featureName,
      genericFeatureName
    );

    setIsInFeature(isActiveFeature);
  }, [
    featureName,
    genericFeatureName,
    findIfIsActiveFeature,
    findIfIsABActiveFeature,
    setIsInFeature
  ]);

  return isInFeature;
};
