import Loadable from "react-loadable";

import { EmptyComponent } from "../common/EmptyComponent";

export const SettingsMobile = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "settings/mobile/settings" */

      "./Settings.mobile.MenuItem"
    ),
  loading: EmptyComponent
});

export const SettingsPC = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "settings/pc/settings.header" */

      "./Settings.pc.HeaderItem"
    ),
  loading: EmptyComponent
});
