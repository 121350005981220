import { Channel, OpeningMode, Topic } from "../../../constants";
import {
  OverlayActionCreators,
  UIActionCreators
} from "../../../redux/action-creators";
import { MessageBrokerUtils } from "../../../utils";
import { AppHelpers } from "../helpers";

export class WidgetContainerService {
  constructor(dispatch) {
    this._dispatch = dispatch;
  }

  handleCreate = () => {
    MessageBrokerUtils.subscribe({
      channel: Channel.UI,
      topic: Topic.CreateAppContainer,
      callback: data => {
        const { openingMode } = data;
        switch (openingMode) {
          case OpeningMode.RightSideDrawer:
            this._handleRightSideDrawer(this._dispatch, data);
            break;
          case OpeningMode.FullScreenPopup:
            this._handleFullScreenPopup(this._dispatch, data);
            break;
          case OpeningMode.MainView:
            this._handleMainView(this._dispatch, data);
            break;
          case OpeningMode.Popup: {
            this._handlePopup(this._dispatch, data);
            break;
          }
          case OpeningMode.bubble: {
            this._handleBubble(this._dispatch, data);
            break;
          }
          default:
            break;
        }
      }
    });
  };

  handleToggle = () => {
    MessageBrokerUtils.subscribe({
      channel: Channel.UI,
      topic: Topic.toggleAppContainer,
      callback: data => {
        const { openingMode } = data;
        switch (openingMode) {
          case OpeningMode.RightSideDrawer:
            this._dispatch(
              UIActionCreators.updateRightSideDrawer({
                ...data,
                isVisible: true
              })
            );
            break;
          default:
            break;
        }
      }
    });
  };

  _handleRightSideDrawer = (dispatch, data) => {
    AppHelpers.toggleBetslipVisibility(false);
    dispatch(
      UIActionCreators.addElementRightSideDrawer({
        ...data,
        isVisible: true,
        isMinimized: false,
        error: undefined,
        callback: undefined
      })
    );
  };

  _handleFullScreenPopup = (dispatch, data) => {
    dispatch(UIActionCreators.createFullScreenPopup(data));
  };

  _handleMainView = (dispatch, data) => {
    dispatch(UIActionCreators.toggleMainViewApp(data));
  };

  _handlePopup = (dispatch, data) => {
    AppHelpers.appContainerReady(data);
    const { activeOverlayData } = data || {};
    const { mft } = data?.launchInfo?.appSpecificParameters || {};

    let appInitSucceededSub;
    let appErrorSub;
    let appInitFailedSub;

    const unSubAppActions = () => {
      appInitSucceededSub.unsubscribe();
      appErrorSub.unsubscribe();
      appInitFailedSub.unsubscribe();
    };

    appInitSucceededSub = MessageBrokerUtils.subscribe({
      channel: Channel.SESSION,
      topic: Topic.appInitSucceeded,
      callback: () => {
        AppHelpers.showRedirectMFTPopup(mft, activeOverlayData?.action);
        unSubAppActions();
      }
    });
    appErrorSub = MessageBrokerUtils.subscribe({
      channel: Channel.SESSION,
      topic: Topic.appError,
      callback: () => {
        dispatch(OverlayActionCreators.hide());
        unSubAppActions();
      }
    });
    appInitFailedSub = MessageBrokerUtils.subscribe({
      channel: Channel.SESSION,
      topic: Topic.appInitFailed,
      callback: () => {
        dispatch(OverlayActionCreators.hide());
        unSubAppActions();
      }
    });
  };

  _handleBubble = (dispatch, data) => {
    dispatch(UIActionCreators.toggleBubble(data));
  };
}
