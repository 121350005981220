export class GA {
  static logEvent({ eventCategory, eventAction, eventLabel }) {
    if (typeof window.ga === "function") {
      window.ga("send", {
        hitType: "event",
        eventCategory,
        eventAction,
        eventLabel
      });
    } else {
      console.log("Google Analytics not found!");
    }
  }
}
