import classNames from "classnames";

const getDisplayedText = (value, formatMessage) => {
  if (!value) return "";

  if (typeof value === "string") return value;
  if (typeof value === "object") return formatMessage(value);

  return "";
};

const composeClasses = ({ className, isActive }) => {
  return classNames({
    [className]: !!className,
    item_active: isActive
  });
};

const MenuItemLogic = {
  getDisplayedText,
  composeClasses
};

export default MenuItemLogic;
