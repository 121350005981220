const getControlCenterReducer = state => state.controlCenter;

export default {
  initiatorSource: store => getControlCenterReducer(store).initiatorSource,
  fullCorrelationId: store => getControlCenterReducer(store).fullCorrelationId,
  fullOpenScope: store => getControlCenterReducer(store).fullOpenScope,
  fullRendered: store => getControlCenterReducer(store).fullRendered,
  fullVisible: store => getControlCenterReducer(store).fullVisible,
  miniCorrelationId: store => getControlCenterReducer(store).miniCorrelationId,
  miniOpenScope: store => getControlCenterReducer(store).miniOpenScope,
  miniRendered: store => getControlCenterReducer(store).miniRendered,
  miniVisible: store => getControlCenterReducer(store).miniVisible,
  enabled: store => getControlCenterReducer(store).enabled
};
