import { GeneralUtils } from "../../utils/generalUtils";
import { NativeUtils } from "../../utils/nativeUtils";
import { createSelector } from "reselect";
import { LocalStorageUtils } from "../../utils/localStorageUtils";

const getUserContextReducer = state => state.userContext;

const isNative = NativeUtils.findIfIsNative();
const selectors = {
  bonusBalanceNumber: store => getUserContextReducer(store).bonusBalance,
  userTickets: store => {
    const {
      userTickets: { tickets },
      cid
    } = getUserContextReducer(store);
    const readTickets = new Set(
      (LocalStorageUtils.get(`readTicketIds-${cid}`) || "").split(",")
    );
    const ticketIds = Object.keys(tickets);
    const unreadCount = ticketIds.reduce(
      (acc, id) => (!readTickets.has(id) ? ++acc : acc),
      0
    );
    const totalTicketCount = ticketIds.length;

    return {
      userTickets: tickets,
      totalTicketCount,
      unreadCount
    };
  },
  brandId: store => getUserContextReducer(store).brandId,
  cid: store => getUserContextReducer(store).cid,
  culture: store => getUserContextReducer(store).culture,
  currency: store => getUserContextReducer(store).currency,
  deviceType: store => getUserContextReducer(store).deviceType.toLowerCase(),
  firstName: store => getUserContextReducer(store).firstName,
  isAuthenticated: store => !!getUserContextReducer(store).isAuthenticated,
  isEligible: store => {
    const cashierDetails = getUserContextReducer(store).miniCashierDetails;
    return (cashierDetails && cashierDetails.IsEligible) || false;
  },
  //TO DO - rename this to better reflect what its value means
  //isMobile from frontend, handling resizing, etc..
  isMobile: store =>
    getUserContextReducer(store).deviceType.toLowerCase() === "mobile",
  isPC: store => getUserContextReducer(store).deviceType.toLowerCase() === "pc",
  //TO DO - add this to an object or rename to better refelct what its value means
  //information from backend - DDR, native - frontend
  //TO DO - save device information in store and use composed selectors
  isNativeIOS: store => getUserContextReducer(store).isNativeIOS,
  isNative: store => getUserContextReducer(store).isNative || isNative,
  isMobileDevice: store => getUserContextReducer(store).isMobileDevice,
  isMobileWebApp: store =>
    getUserContextReducer(store).isMobileWebApp && !isNative,
  isVip: store => getUserContextReducer(store).isVip,
  lastLogin: store => getUserContextReducer(store).lastLogin,
  miniCashierDetails: store => getUserContextReducer(store).miniCashierDetails,
  miniCashierEnabled: store => getUserContextReducer(store).miniCashierEnabled,
  oddsFormat: store => getUserContextReducer(store).oddsFormat,
  oddsFormatSettings: store => getUserContextReducer(store).oddsFormatSettings,
  iconUrls: store => getUserContextReducer(store).iconUrls,
  realBRNumber: store => getUserContextReducer(store).realBR,
  registrationCountry: store =>
    getUserContextReducer(store).registrationCountry,
  RMS: store => getUserContextReducer(store).RMS,
  sessionTime: store => getUserContextReducer(store).sessionTime,
  showBonusBalance: store => !!getUserContextReducer(store).showBonusBalance,
  subBrandId: store => getUserContextReducer(store).subBrandId,
  userBalanceNumber: store => getUserContextReducer(store).userBalance,
  userCountry: store => getUserContextReducer(store).userCountry,
  username: store => getUserContextReducer(store).username,
  nativeUXVersion: store => getUserContextReducer(store).nativeUXVersion,
  isIOS: store => getUserContextReducer(store).isIOS,
  isPromotionRestricted: store =>
    getUserContextReducer(store).isPromotionRestricted,
  isPromotionPermitted: store =>
    getUserContextReducer(store).isPromotionPermitted
};

const userBalance = createSelector(
  [selectors.userBalanceNumber, selectors.culture, selectors.currency],
  (userBalanceNumber, culture, currency) => {
    return GeneralUtils.formatAmount(userBalanceNumber, culture, currency);
  }
);

const realBR = createSelector(
  [selectors.realBRNumber, selectors.culture, selectors.currency],
  (realBRNumber, culture, currency) =>
    GeneralUtils.formatAmount(realBRNumber, culture, currency)
);

const bonusBalance = createSelector(
  [selectors.bonusBalanceNumber, selectors.culture, selectors.currency],
  (bonusBalanceNumber, culture, currency) =>
    GeneralUtils.formatAmount(bonusBalanceNumber, culture, currency)
);

const displayName = createSelector([selectors.firstName], firstName => {
  return firstName;
});

export default { ...selectors, userBalance, realBR, bonusBalance, displayName };
