import { Channel, Topic } from "../constants";
import { MessageBrokerUtils } from "../utils";
import { useCallback } from "react";
import { performAction } from "../utils/actionUtils";

export const useAccountCommunicationHandlers = () => {
  const openMyAccount = useCallback(data => {
    MessageBrokerUtils.publish({
      channel: Channel.PLAYER,
      topic: Topic.CTA_MyAccount_Open,
      headers: { publisher: "UAA" },
      data: data
    });
  }, []);

  const openLogin = useCallback(() => {
    MessageBrokerUtils.publish({
      channel: Channel.PLAYER,
      topic: Topic.loginCompletion,
      headers: { publisher: "UAA" }
    });
  }, []);

  const openRegister = useCallback(() => {
    performAction("register");
  }, []);

  const openChallengesOrPromotions = useCallback(() => {
    // the logic to determine promotions or challenges is implemented in the action itself
    performAction("openPromotions");
  }, []);

  return {
    openMyAccount,
    openLogin,
    openRegister,
    openChallengesOrPromotions
  };
};
