import { UIActionCreators } from "../redux/action-creators";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const eventOptions = {
  passive: true,
  capture: true
};

const toggleInteractionHandler = (isAdd, callback) => {
  const action = isAdd ? window.addEventListener : window.removeEventListener;
  action("click", callback, eventOptions);
  action("scroll", callback, eventOptions);
  action("touchStart", callback, eventOptions);
};

export const useUserInteraction = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const action = () => {
      toggleInteractionHandler(false, action);

      dispatch(UIActionCreators.userInteracted());
    };
    toggleInteractionHandler(true, action);
  }, [dispatch]);
};
