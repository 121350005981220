import { Channel, Topic, WIDTH_TRANSITION_VALUE } from "../constants";
import {
  MenuActionCreators,
  UserContextActionCreators
} from "../redux/action-creators";
import { MenuSelectors, UserContextSelectors } from "../redux/selectors";
import { MessageBrokerUtils, UIUtils } from "../utils";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useDeviceUpdateSubscription = () => {
  const dispatch = useDispatch();
  const deviceType = useSelector(UserContextSelectors.deviceType);
  const [newDeviceType, setNewDeviceType] = useState(deviceType);
  const quickLinksInnerMenuVisible = useSelector(
    MenuSelectors.quickLinksInnerMenuVisible
  );

  const hideMenus = useCallback(() => {
    dispatch(MenuActionCreators.hideHamburgerMenu());
    if (quickLinksInnerMenuVisible) {
      dispatch(MenuActionCreators.hideQuickLinksInnerMenu());
    }
  }, [dispatch, quickLinksInnerMenuVisible]);

  useEffect(() => {
    let defaultDeviceType = "Mobile";
    if (UIUtils.getWidth() > WIDTH_TRANSITION_VALUE) {
      defaultDeviceType = "PC";
    }

    console.log(
      `[APP.js] - Setting default device type to: ${defaultDeviceType}`
    );

    setNewDeviceType(defaultDeviceType);
  }, []);

  const setOddsFormat = useCallback(
    code => {
      dispatch(UserContextActionCreators.setOddsFormat(code));
    },
    [dispatch]
  );

  const setPlayerPreferences = useCallback(
    (data, envelope) => {
      if (envelope && envelope.headers.version === 2 && data.oddsFormat)
        setOddsFormat(data.oddsFormat);
      else setOddsFormat(data);
    },
    [setOddsFormat]
  );

  useEffect(() => {
    const playerPreferencesSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.PLAYER,
      topic: Topic.playerPreferences,
      callback: setPlayerPreferences
    });

    return () => {
      playerPreferencesSubscription?.unsubscribe();
    };
  }, [setPlayerPreferences]);

  useEffect(() => {
    const updateDeviceSubscriptionHandler = data => {
      console.log(
        `[APP.js] - DeviceUpdated event catched, type is: ${data.Type}`
      );

      setNewDeviceType(data.Type);
    };

    const updateDeviceTopicSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.UI,
      topic: Topic.DeviceUpdate,
      callback: updateDeviceSubscriptionHandler
    });

    return () => {
      console.log("[APP.js] - Unsubscribing from update device channel...");

      updateDeviceTopicSubscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (deviceType?.toLowerCase() !== newDeviceType?.toLowerCase()) {
      dispatch(UserContextActionCreators.updateDevice(newDeviceType));
      hideMenus();
    }
  }, [deviceType, dispatch, hideMenus, newDeviceType]);
};
