const getSearchReducer = state => state.search;

export default {
  results: state => getSearchReducer(state).results || [],
  error: state => getSearchReducer(state).error,
  historySearchTerms: state => getSearchReducer(state).historySearchTerms || [],
  searchUrl: state => getSearchReducer(state).searchUrl,
  searchTerm: state => getSearchReducer(state).searchTerm || "",
  searchActive: state => getSearchReducer(state).searchActive,
  settings: state => getSearchReducer(state).settings
};
