export default {
  GET_SESSION_TIME: "userContext/GET_SESSION_TIME",
  GET_SESSION_TIME_ERROR: "userContext/GET_SESSION_TIME_ERROR",
  GET_SESSION_TIME_FULFILLED: "userContext/GET_SESSION_TIME_FULFILLED",
  SESSION_TIME_TASK_STOPPED: "userContext/SESSION_TIME_TASK_STOPPED",
  SET_ODDS_FORMAT: "userContext/SET_ODDS_FORMAT",
  START_SESSION_TIME_TASK: "userContext/START_SESSION_TIME_TASK",
  STOP_SESSION_TIME_TASK: "userContext/STOP_SESSION_TIME_TASK",
  UPDATE_BALANCE: "userContext/UPDATE_BALANCE",
  UPDATE_TICKETS: "userContext/UPDATE_TICKETS",
  ALL_TICKETS_SEEN: "userContext/ALL_TICKETS_SEEN",
  UPDATE_DEVICE: "userContext/UPDATE_DEVICE",
  UPDATE_MINI_CASHIER_DETAILS: "userContext/UPDATE_MINI_CASHIER_DETAILS",
  UPDATE_IS_NATIVE: "userContext/UPDATE_IS_NATIVE",
  UPDATE_IS_NATIVE_IOS: "userContext/UPDATE_IS_NATIVE_IOS",
  UPDATE_IS_MOBILE_WEB_APP: "userContext/UPDATE_IS_MOBILE_WEB_APP",
  UPDATE_IS_MOBILE_DEVICE: "userContext/UPDATE_IS_MOBILE_DEVICE"
};
