import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { useClickOutside } from "../../../hooks";

export const MenuTooltip = ({ favouriteError, onHide }) => {
  const currentRef = useClickOutside(() => {
    if (typeof onHide === "function") onHide();
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof onHide === "function") {
        onHide();
      }
    }, 5000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [onHide]);

  return (
    <div ref={currentRef} className="menu-tooltip">
      {favouriteError}
    </div>
  );
};

MenuTooltip.propTypes = {
  favouriteError: PropTypes.string,
  onHide: PropTypes.func
};

export default React.memo(MenuTooltip);
