const getSettingsGeneralReducer = state => state.settings.general;
const getSettingsOddsReducer = state => state.settings.odds;
const getSettingsAppearanceReducer = state => state.settings.appearance;
const getSettingsLanguageReducer = state => state.settings.language;
const getSettingsCountryReducer = state => state.settings.country;

export default {
  general: {
    isDrawerActive: store => !!getSettingsGeneralReducer(store).isDrawerActive,
    isDrawerLoaded: store => !!getSettingsGeneralReducer(store).isDrawerLoaded
  },
  odds: {
    current: store => getSettingsOddsReducer(store).current,
    settings: store => getSettingsOddsReducer(store).settings
  },
  appearance: {
    current: store => getSettingsAppearanceReducer(store).current,
    settings: store => getSettingsAppearanceReducer(store).settings
  },
  language: {
    title: store => getSettingsLanguageReducer(store).title,
    current: store => getSettingsLanguageReducer(store).current,
    settings: store => getSettingsLanguageReducer(store).settings
  },
  country: {
    title: store => getSettingsCountryReducer(store).title,
    settings: store => getSettingsCountryReducer(store).settings
  }
};
