import { WidgetContainerService } from "./services/widget-container.service";
import { WidgetErrorService } from "./services/widget-error.service";
import { WidgetDisposeService } from "./services/widget-remove.service";

export class Widget {
  constructor(dispatch, initTimer) {
    this._containerService = new WidgetContainerService(dispatch);
    this._errorService = new WidgetErrorService(dispatch);
    this._disposeService = new WidgetDisposeService(dispatch, initTimer);
  }

  handleCreateContainer = () => {
    this._containerService.handleCreate();
  };

  handleToggleContainer = () => {
    this._containerService.handleToggle();
  };

  handleDispose = () => {
    this._disposeService.handle();
  };

  handleError = () => {
    this._errorService.handle();
  };
}
