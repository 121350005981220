///<reference path="../../typings/library.manager.d.ts" />

import { useEffect } from "react";
import { UserareaLibrary } from "library-manager";

export const useUserarea = () => {
  useEffect(() => {
    UserareaLibrary?.loaded();
    document
      .querySelector("#uc-wrapper-container")
      .classList.remove("visible--el");

    setTimeout(() => {
      document
        .querySelector(".uc-content-area")
        .classList.remove("visible--el");
    }, 100);
  }, []);
};
