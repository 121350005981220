import { Channel, Topic } from "../constants";
import {
  GlobalActionCreators,
  MenuActionCreators
} from "../redux/action-creators";

import { MessageBrokerUtils } from "../utils";
import { useDispatch } from "react-redux";
import { useEffect, useCallback } from "react";

export const useAccountSubscription = () => {
  const dispatch = useDispatch();

  const handleLoginSuccess = useCallback(
    data => {
      document.body.classList.add("authenticated");
      dispatch(GlobalActionCreators.updateState(data.InitialState));
      console.log("Login success, updating state");
    },
    [dispatch]
  );

  const handleLogoutSuccess = useCallback(
    data => {
      document.body.classList.remove("authenticated");
      dispatch(GlobalActionCreators.initState(data.InitialState));
      dispatch(MenuActionCreators.setFavouriteSports([]));
      console.log("Logout success, updating state");
    },
    [dispatch]
  );

  useEffect(() => {
    const loginSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.IDENTITY,
      topic: Topic.loginSuccess,
      callback: handleLoginSuccess
    });

    const logoutSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.IDENTITY,
      topic: Topic.logoutSuccess,
      callback: handleLogoutSuccess
    });

    return () => {
      loginSubscription?.unsubscribe();
      logoutSubscription?.unsubscribe();
    };
  }, [dispatch, handleLoginSuccess, handleLogoutSuccess]);
};
