import { Channel, Topic } from "../constants";

import { MessageBrokerUtils } from "../utils";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { MenuActionCreators } from "../redux/action-creators";
import { useSelector } from "react-redux";
import { MenuSelectors, UISelectors } from "../redux/selectors";

export const useToggleHamburgerMenu = () => {
  const userInteracted = useSelector(UISelectors.userInteracted);
  const dispatch = useDispatch();
  const hamburgerMenuVisible = useSelector(MenuSelectors.hamburgerMenuVisible);
  const drawersLoaded = useSelector(MenuSelectors.drawersLoaded);
  const [delayedShowHamburgerMenu, setDelayedShowHamburgerMenu] =
    useState(false);

  const ToggleHamburgerMenu = useCallback(() => {
    if (hamburgerMenuVisible) {
      dispatch(MenuActionCreators.hideHamburgerMenu());
    } else if (drawersLoaded && !hamburgerMenuVisible) {
      dispatch(MenuActionCreators.showHamburgerMenu());
    } else {
      setDelayedShowHamburgerMenu(true);
    }
  }, [dispatch, hamburgerMenuVisible, drawersLoaded]);

  useEffect(() => {
    if (delayedShowHamburgerMenu && drawersLoaded) {
      dispatch(MenuActionCreators.showHamburgerMenu());
    }
  }, [delayedShowHamburgerMenu, dispatch, drawersLoaded]);

  useEffect(() => {
    const toggleHamburgerMenuSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.USER_INTERACTION,
      topic: Topic.BurgerMenu_Toggle,
      callback: ToggleHamburgerMenu
    });

    return () => {
      toggleHamburgerMenuSubscription?.unsubscribe();
    };
  }, [dispatch, userInteracted, hamburgerMenuVisible, ToggleHamburgerMenu]);
};
