import { ClientsFrameworkLibrary } from "library-manager";
export class ClientsFrameworkService {
  initGetUserBalanceAsync = async () => {
    await ClientsFrameworkLibrary.ready;
    window.PF.Web.ClientsFramework.Init.getUserBalance();
  };

  registrationOpenAsync = async (width, height) => {
    await ClientsFrameworkLibrary.ready;
    window.PF.Web.ClientsFramework.Registration.Open(width, height);
  };

  postLoginInitAsync = async () => {
    await ClientsFrameworkLibrary.ready;
    window.PF.Web.ClientsFramework.PostLogin.Init();
  };

  openClientProductPackageClientAutoLoginAsync = async (
    productOfferingId,
    subBrandId,
    productPackageId,
    isRealMode,
    additionalActivationParameters
  ) => {
    await ClientsFrameworkLibrary.ready;
    window.PF?.Web.ClientsFramework.OpenClientProductPackage.clientAutoLogin(
      productOfferingId,
      subBrandId,
      productPackageId,
      isRealMode,
      additionalActivationParameters
    );
  };

  cleanUserStorageItemsAsync = async () => {
    await ClientsFrameworkLibrary.ready;
    window.PF.Web.ClientsFramework.Logout.cleanUserStorageItems();
  };

  logoutDoLogoutWithoutRefreshAsync = async kickResonId => {
    await ClientsFrameworkLibrary.ready;
    window.PF.Web.ClientsFramework.Logout.doLogoutWithoutRefresh(kickResonId);
  };

  logoutDoLogoutAsync = async reason => {
    await ClientsFrameworkLibrary.ready;
    window.PF.Web.ClientsFramework.Logout.doLogout(reason);
  };

  logoutdoLogoutRefreshStateAsync = async (logoutData, reasonCode) => {
    await ClientsFrameworkLibrary.ready;
    window.PF.Web.ClientsFramework.Logout.doLogoutRefreshState(
      logoutData,
      reasonCode
    );
  };
}
