import { useEffect, useState } from "react";

import { MenuSelectors } from "../redux/selectors";
import { useSelector } from "react-redux";

let timeoutFn;
const TRANSITION_TIMEOUT = 200; //ms

export const useInnerMenuTransition = () => {
  const quickLinksInnerMenuVisible = useSelector(
    MenuSelectors.quickLinksInnerMenuVisible
  );
  const [overflowVisible, setOverflowVisible] = useState(false);

  useEffect(() => {
    if (!quickLinksInnerMenuVisible) {
      timeoutFn = setTimeout(
        () => setOverflowVisible(false),
        TRANSITION_TIMEOUT
      );
    } else {
      clearTimeout(timeoutFn);
      setOverflowVisible(true);
    }
  }, [quickLinksInnerMenuVisible]);

  return overflowVisible;
};
