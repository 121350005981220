export default {
  CLIENT_INITIALIZED: "ui/CLIENT_INITIALIZED",
  CREATE_FULL_SCREEN_POPUP: "ui/CREATE_FULL_SCREEN_POPUP",
  CLOSE_PANIC_TOOLTIP: "ui/CLOSE_PANIC_TOOLTIP",
  SET_LINKS: "ui/SET_LINKS",
  SET_CONFIG: "ui/SET_CONFIG",
  SET_SCREEN_TYPE: "ui/SET_SCREEN_TYPE",
  SET_TOUCHID_VISIBLE: "ui/SET_TOUCHID_VISIBLE",
  SET_TOUCHID_DATA: "ui/SET_TOUCHID_DATA",
  UPDATE_RIGHT_SIDE_DRAWER: "ui/UPDATE_RIGHT_SIDE_DRAWER",
  ADD_ELEMENT_RIGHT_SIDE_DRAWER: "ui/ADD_ELEMENT_RIGHT_SIDE_DRAWER",
  REMOVE_ELEMENT_RIGHT_SIDE_DRAWER: "ui/REMOVE_ELEMENT_RIGHT_SIDE_DRAWER",
  TOGGLE_MAIN_VIEW_APP: "ui/TOGGLE_MAIN_VIEW_APP",
  USER_INTERACTED: "ui/USER_INTERACTED",
  TOGGLE_BUBBLE: "ui/TOGGLE_BUBBLE",
  SET_LAST_BUBBLE_POSITION: "ui/SET_LAST_BUBBLE_POSITION",
  SET_THEME_MODE: "ui/SET_THEME_MODE",
  SET_DEVICE_ORIENTATION: "ui/SET_DEVICE_ORIENTATION",
  DISABLE_POPUPS: "ui/DISABLE_POPUPS",
  RENDER_CONTROL_CENTER_MINI: "ui/RENDER_CONTROL_CENTER_MINI",
  SET_POPUP_VISIBILITY: "ui/SET_POPUP_VISIBILITY",
  SET_NO_SCROLL: "ui/SET_NO_SCROLL"
};
