import { HeaderActionConstants } from "../action-constants";

const setBodyClassName = bodyClassName => ({
  type: HeaderActionConstants.SET_BODY_CLASS_NAME,
  payload: bodyClassName
});

const setBetsCount = betsCount => ({
  type: HeaderActionConstants.SET_BETS_COUNT,
  payload: betsCount
});

const openMyBets = () => ({
  type: HeaderActionConstants.OPEN_MY_BETS
});

const setHeaderHeight = height => ({
  type: HeaderActionConstants.SET_HEADER_HEIGHT,
  payload: height
});

const collapseCrossActivity = isCollapsed => ({
  type: HeaderActionConstants.COLLAPSE_CROSS_ACTIVITY,
  payload: isCollapsed
});

export default {
  setBodyClassName,
  setBetsCount,
  openMyBets,
  setHeaderHeight,
  collapseCrossActivity
};
