import { MenuActionConstants } from "../action-constants";

const select = payload => ({
  type: MenuActionConstants.SELECT,
  payload
});

const setSportCategories = sportCategories => ({
  type: MenuActionConstants.SET_SPORT_CATEGORIES,
  payload: sportCategories
});

const setFavouriteSports = favouriteSports => ({
  type: MenuActionConstants.SET_FAVOURITE_SPORTS,
  payload: favouriteSports
});

const setFavouriteSportsSelectable = isSelectable => ({
  type: MenuActionConstants.SET_FAVOURITE_SPORTS_SELECTABLE,
  payload: isSelectable
});

const setFavouriteSportsRunningInsideInnerMenu =
  favouriteSportsRunningInsideInnerMenu => ({
    type: MenuActionConstants.SET_FAVOURITE_SPORTS_RUNNING_INSIDE_INNER_MENU,
    payload: favouriteSportsRunningInsideInnerMenu
  });

const setHomeSectionCategories = homeSectionCategories => ({
  type: MenuActionConstants.SET_HOME_SECTION_CATEGORIES,
  payload: homeSectionCategories
});

const setLoyaltySectionCategories = loyaltySectionCategories => ({
  type: MenuActionConstants.SET_LOYALTY_SECTION_CATEGORIES,
  payload: loyaltySectionCategories
});

const setInnerMenuIgnoreClass = className => ({
  type: MenuActionConstants.SET_INNER_MENU_IGNORE_CLASS,
  payload: className
});

const setDrawersRendering = loading => ({
  type: MenuActionConstants.DRAWERS_RENDERING,
  payload: loading
});

const setDrawersLoaded = loaded => ({
  type: MenuActionConstants.DRAWERS_LOADED,
  payload: loaded
});

const showHamburgerMenu = () => ({
  type: MenuActionConstants.SHOW_HAMBURGER_MENU
});

const hideHamburgerMenu = () => ({
  type: MenuActionConstants.HIDE_HAMBURGER_MENU
});

const showLanguageMenu = () => ({
  type: MenuActionConstants.SHOW_LANGUAGE_MENU
});

const hideLanguageMenu = () => ({
  type: MenuActionConstants.HIDE_LANGUAGE_MENU
});

const showOddsMenu = () => ({
  type: MenuActionConstants.SHOW_ODDS_MENU
});

const hideOddsMenu = () => ({
  type: MenuActionConstants.HIDE_ODDS_MENU
});

const showQuickLinksInnerMenu = itemId => ({
  type: MenuActionConstants.SHOW_QUICK_LINKS_INNER_MENU,
  payload: itemId
});

const hideQuickLinksInnerMenu = () => ({
  type: MenuActionConstants.HIDE_QUICK_LINKS_INNER_MENU
});

const showAppearanceMenu = () => ({
  type: MenuActionConstants.SHOW_APPEARANCE_MENU
});

const hideAppearanceMenu = () => ({
  type: MenuActionConstants.HIDE_APPEARANCE_MENU
});

const setNumberOfDisplayedItems = payload => ({
  type: MenuActionConstants.SET_NUMBER_OF_DISPLAYED_ITEMS,
  payload: payload
});

const setMenuItems = payload => ({
  type: MenuActionConstants.SET_MENU_ITEMS,
  payload: payload
});

export default {
  select,
  setFavouriteSports,
  setFavouriteSportsRunningInsideInnerMenu,
  setFavouriteSportsSelectable,
  setHomeSectionCategories,
  setInnerMenuIgnoreClass,
  setDrawersRendering,
  showHamburgerMenu,
  hideHamburgerMenu,
  showLanguageMenu,
  hideLanguageMenu,
  showOddsMenu,
  hideOddsMenu,
  showQuickLinksInnerMenu,
  hideQuickLinksInnerMenu,
  showAppearanceMenu,
  hideAppearanceMenu,
  setLoyaltySectionCategories,
  setNumberOfDisplayedItems,
  setSportCategories,
  setMenuItems,
  setDrawersLoaded
};
