import { ScreenOrientation } from "../../constants";

const getUiReducer = state => state.ui;

export default {
  clientInitialized: store => getUiReducer(store).clientInitialized,
  fullScreenPopup: store => getUiReducer(store).fullScreenPopup,
  links: store => getUiReducer(store).links,
  config: store => getUiReducer(store).config,
  rightSideDrawer: store => getUiReducer(store).rightSideDrawer,
  mainViewApp: store => getUiReducer(store).mainViewApp,
  screenType: store => getUiReducer(store).screenType,
  touchIdVisible: store => getUiReducer(store).touchIdVisible,
  touchIdData: store => getUiReducer(store).touchIdData,
  panicTooltipClosed: store => getUiReducer(store).panicTooltipClosed,
  userInteracted: store => getUiReducer(store).userInteracted,
  isThemeModeEnabled: store => getUiReducer(store).isThemeModeEnabled,
  themeMode: store => getUiReducer(store).themeMode,
  themeModeSettings: store => getUiReducer(store).themeModeSettings,
  bubble: store => getUiReducer(store).bubble,
  lastBubblePosition: store => getUiReducer(store).lastBubblePosition,
  isPortrait: store =>
    getUiReducer(store).orientation === ScreenOrientation.Portrait,
  isLandscape: store =>
    getUiReducer(store).orientation === ScreenOrientation.Landscape,
  popupsDisabled: store => getUiReducer(store).popupsDisabled,
  controlCenterMini: store => getUiReducer(store).controlCenterMini,
  isPopupVisible: store => getUiReducer(store).isPopupVisible,
  noScrolls: store => getUiReducer(store).noScrolls
};
