import { Channel, Topic } from "../constants";

import { MessageBrokerUtils } from "../utils";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  AccountActionCreators,
  UIActionCreators
} from "../redux/action-creators";
import { useSelector } from "react-redux";
import { UISelectors } from "../redux/selectors";

export const useToggleUserarea = () => {
  const userInteracted = useSelector(UISelectors.userInteracted);
  const dispatch = useDispatch();

  useEffect(() => {
    const toggleUserarea = () => {
      console.log("toggleUserarea event catched");

      dispatch(AccountActionCreators.toggleAccountDrawer());
      if (!userInteracted) {
        dispatch(UIActionCreators.userInteracted());
      }
    };

    const toggleUserareaSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.UI,
      topic: Topic.toggleUserArea,
      callback: toggleUserarea
    });

    return () => {
      toggleUserareaSubscription?.unsubscribe();
    };
  }, [dispatch, userInteracted]);
};
