import { CategoryType } from "../constants";
import { GeneralUtils } from "../utils";
import { MenuActionCreators } from "../redux/action-creators";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export const useCategoriesSetter = (type, categories) => {
  const dispatch = useDispatch();

  useEffect(() => {
    switch (type) {
      case CategoryType.HomeSection: {
        dispatch(
          MenuActionCreators.setHomeSectionCategories(
            Object.values(categories || {})
          )
        );
        break;
      }
      case CategoryType.Sports: {
        dispatch(
          MenuActionCreators.setSportCategories(
            GeneralUtils.mapMenuItems(categories)
          )
        );

        break;
      }
      default: {
        console.error("[useCategoriesSetter] wrong category.");
      }
    }
  }, [categories, dispatch, type]);
};
