import Loadable from "react-loadable";

import { EmptyComponent } from "../common/EmptyComponent";
import { ClientProviderEnum } from "../../constants";

export const OddsMobile = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "odds/mobile/odds" */

      "./Odds.mobile"
    ),
  loading: EmptyComponent
});

export const OddsPC = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "odds/pc/odds" */

      "./Odds.pc"
    ),
  loading: EmptyComponent
});

export const preload = ({ isPC, clientProvider }) => {
  if (clientProvider === ClientProviderEnum.Spectate) {
    if (isPC) OddsPC.preload();
    else OddsMobile.preload();
  }
};
