import React from "react";
import styled from "styled-components";

const SvgMenuSpinner = styled.svg`
  height: 1.8rem;
  width: 2.3rem;
  margin: auto;
`;

export const MenuSpinner = () => {
  return (
    <SvgMenuSpinner
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      enable-background="new 0 0 40 40"
      xmlSpace="preserve"
      data-testid="uc-menu-spinner"
    >
      <path
        fill="none"
        stroke="var(--spinner-secondary-color)"
        stroke-width="5"
        stroke-linecap="round"
        stroke-miterlimit="10"
        d="M37.5,20
		c0-9.665-7.835-17.5-17.5-17.5S2.5,10.335,2.5,20v0"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="180 20 20"
          to="-180 20 20"
          dur="1.3s"
          repeatCount="indefinite"
        />
      </path>
      <path
        fill="none"
        stroke="#666666"
        stroke-width="5"
        stroke-linecap="round"
        stroke-miterlimit="10"
        d="M37.5,20
		c0-9.665-7.835-17.5-17.5-17.5S2.5,10.335,2.5,20v0"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="360 20 20"
          to="0 20 20"
          dur="1.3s"
          repeatCount="indefinite"
        />
      </path>
      <path
        fill="none"
        stroke="var(--second-color)"
        stroke-width="5"
        stroke-linecap="round"
        stroke-miterlimit="10"
        d="M37.5,20
		c0-9.665-7.835-17.5-17.5-17.5S2.5,10.335,2.5,20v0"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0 20 20"
          to="360 20 20"
          dur="1.3s"
          repeatCount="indefinite"
        />
      </path>
    </SvgMenuSpinner>
  );
};

export default React.memo(MenuSpinner);
