export const Brand = {
  Sport_Scasino: 0,
  Sport_Spoker: 1,
  Sport_Com: 8,
  Sport_IT: 31,
  Sport_ES: 58,
  Sport_DK: 75,
  Sport_RO: 76,
  Sport_SE: 81,
  Sport_SH: 83,
  Sport_DE: 84,
  Sport_CO: 86,
  Sport_CA: 87,
  Sport_NL: 88,
  Sport_VA: 89,
  Sport_IN: 90,
  Sport_MI: 91,
  Sport_MG_SE: 92,
  Sport_MG_DK: 94,
  Sport_MG_MGA: 95,
  Sport_UK: 96
};

export const OfferingClient = {
  Sport: "SPORT",
  Poker: "POKER",
  Casino: "CASINO"
};
