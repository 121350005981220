import { UserContextActionConstants } from "../action-constants";

const updateBalance = payload => ({
  type: UserContextActionConstants.UPDATE_BALANCE,
  payload
});

const updateTickets = payload => ({
  type: UserContextActionConstants.UPDATE_TICKETS,
  payload
});

const allTicketsSeen = () => ({
  type: UserContextActionConstants.ALL_TICKETS_SEEN
});

const getSessionTimeFulfilled = sessionTime => ({
  type: UserContextActionConstants.GET_SESSION_TIME_FULFILLED,
  payload: sessionTime
});

const startSessionTimeTask = () => ({
  type: UserContextActionConstants.START_SESSION_TIME_TASK
});

const stopSessionTimeTask = () => ({
  type: UserContextActionConstants.STOP_SESSION_TIME_TASK
});

const sessionTimeTaskStopped = () => ({
  type: UserContextActionConstants.SESSION_TIME_TASK_STOPPED
});

const getSessionTime = () => ({
  type: UserContextActionConstants.GET_SESSION_TIME
});

const getSessionTimeError = payload => ({
  type: UserContextActionConstants.GET_SESSION_TIME_ERROR,
  payload
});

const updateDevice = payload => ({
  type: UserContextActionConstants.UPDATE_DEVICE,
  payload
});

const updateMiniCashierDetails = payload => ({
  type: UserContextActionConstants.UPDATE_MINI_CASHIER_DETAILS,
  payload
});

const updateIsNative = payload => ({
  type: UserContextActionConstants.UPDATE_IS_NATIVE,
  payload
});

const updateIsNativeIOS = payload => ({
  type: UserContextActionConstants.UPDATE_IS_NATIVE_IOS,
  payload
});

const updateIsMobileWebApp = payload => ({
  type: UserContextActionConstants.UPDATE_IS_MOBILE_WEB_APP,
  payload
});

const updateIsMobileDevice = payload => ({
  type: UserContextActionConstants.UPDATE_IS_MOBILE_DEVICE,
  payload
});

const setOddsFormat = payload => ({
  type: UserContextActionConstants.SET_ODDS_FORMAT,
  payload
});

export default {
  getSessionTime,
  getSessionTimeError,
  getSessionTimeFulfilled,
  sessionTimeTaskStopped,
  setOddsFormat,
  startSessionTimeTask,
  stopSessionTimeTask,
  updateBalance,
  updateTickets,
  allTicketsSeen,
  updateDevice,
  updateMiniCashierDetails,
  updateIsNative,
  updateIsNativeIOS,
  updateIsMobileWebApp,
  updateIsMobileDevice
};
