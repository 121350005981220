export default {
  CLEAR_HISTORY: "search/CLEAR_HISTORY",
  CLEAR_RESULTS: "search/CLEAR_RESULTS",
  INIT_HISTORY: "search/INIT_HISTORY",
  REQUEST: "search/REQUEST",
  REQUEST_ERROR: "search/REQUEST_ERROR",
  REQUEST_FULFILLED: "search/REQUEST_FULFILLED",
  SET_HISTORY: "search/SET_HISTORY",
  SET_TERM: "search/SET_TERM",
  UPDATE_HISTORY: "search/UPDATE_HISTORY",
  UPDATE_STATE_FROM_SESSION: "search/UPDATE_STATE_FROM_SESSION",
  TOGGLE_SEARCH_DRAWER: "search/TOGGLE_SEARCH_DRAWER",
  SET_SETTINGS: "search/SET_SETTINGS"
};
