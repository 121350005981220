import { EmptyComponent } from "./EmptyComponent";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { getSVGImagePath } from "./SVGIcon/SVGImagePath";
import { ImageIcon } from "./ImageIcon";
import { DivIcon } from "./DivIcon";

const SVGIconComponent = props => {
  const {
    iconName,
    dataTestId,
    asBackground,
    asImage,
    children = null,
    className,
    ...restProps
  } = props;

  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(getSVGImagePath(iconName));
  }, [iconName]);

  const classes = useMemo(
    () =>
      classNames("svg-icon", `icon-${iconName}`, {
        [className]: !!className
      }),
    [iconName, className]
  );

  if (asImage) {
    return (
      <ImageIcon
        {...restProps}
        iconName={iconName}
        dataTestId={dataTestId}
        path={path}
        classNames={classes}
      >
        {children}
      </ImageIcon>
    );
  }

  if (!iconName) return <EmptyComponent />;

  return (
    <DivIcon
      {...restProps}
      dataTestId={dataTestId}
      asBackground={asBackground}
      path={path}
      classNames={classes}
    >
      {children}
    </DivIcon>
  );
};

SVGIconComponent.propTypes = {
  iconName: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  children: PropTypes.any,
  asBackground: PropTypes.bool,
  asImage: PropTypes.bool,
  className: PropTypes.string
};

export const SVGIcon2 = React.memo(SVGIconComponent);
