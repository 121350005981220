import Loadable from "react-loadable";
import { EmptyComponent } from "../../components/common/EmptyComponent";

export const loadSecondaryMenuComponent = (brandId, clientProvider) =>
  Loadable({
    loader: async () => {
      const dynamicPart = `${brandId}/${clientProvider}/`;
      try {
        const component = await import(
          /* webpackChunkName: "[request]" */

          `../Brands/${dynamicPart}SecondaryMenu.layout`
        );

        return component;
      } catch (e) {
        console.warn(
          "Userarea: loadSecondaryMenuComponent: warning: SecondaryMenu component is not defined.",
          e
        );
        return EmptyComponent;
      }
    },
    loading: EmptyComponent
  });

export const preload = ({ clientProvider, brandId }) => {
  loadSecondaryMenuComponent(brandId, clientProvider).preload();
};
