import { EmptyComponent } from "../../components/common/EmptyComponent";
import Loadable from "react-loadable";
import React from "react";
import emptyState from "../../redux/store/emptyState";
import { preloadResources as preloadCrossSellResources } from "../CrossActivity/loader";
import { preload as preloadLanguageHeaderMenuItem } from "../../components/LanguageSwitcher";
import { preload as preloadOddsFormatItem } from "../../components/Odds";
import { SettingsPC } from "../../components/Settings/loader";
import { PageContextUtils } from "../../utils";

export const HeaderChunk = Loadable({
  loader: () => import(/* webpackChunkName: "header" */ "./Header"),
  loading: EmptyComponent
});

export const HeaderButtonsPC = Loadable({
  loader: () =>
    import(/* webpackChunkName: "header/pc/buttons" */ "./HeaderButtonsPC"),
  render(loaded, props) {
    let Component = loaded.HeaderButtonsPC;
    return <Component {...props} />;
  },
  loading: EmptyComponent
});

export const HeaderButtonsMobile = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "header/mobile/buttons" */ "./HeaderButtonsMobile"
    ),
  render(loaded, props) {
    let Component = loaded.HeaderButtonsMobile;
    return <Component {...props} />;
  },
  loading: EmptyComponent
});

export const HeaderLogoReward = Loadable({
  loader: () =>
    import(/* webpackChunkName: "header/logo-reward" */ "./HeaderLogoReward"),
  render(loaded, props) {
    let Component = loaded.HeaderLogoReward;
    return <Component {...props} />;
  },
  loading: EmptyComponent
});

export const getHeaderLayoutComponent = (brandId, clientProvider, deviceType) =>
  Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "[request]" */

        `../Brands/${brandId}/${clientProvider}/Header.${deviceType?.toLowerCase()}.layout`
      ),

    loading: EmptyComponent
  });

export const getLeftHeaderLayoutComponent = (
  brandId,
  clientProvider,
  deviceType
) =>
  Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "[request]" */

        `../Brands/${brandId}/${clientProvider}/LeftHeader.${deviceType?.toLowerCase()}.layout`
      ),

    loading: EmptyComponent
  });

export const getHeaderLayout = (brandId, deviceType) => {
  let layout = React.Fragment;
  const { name } = PageContextUtils.getClientProviderData();
  layout = getHeaderLayoutComponent(brandId, name, deviceType);

  return layout;
};

export const getLeftHeaderLayout = (brandId, deviceType) => {
  let layout = React.Fragment;
  const { name } = PageContextUtils.getClientProviderData();
  layout = getLeftHeaderLayoutComponent(brandId, name, deviceType);

  return layout;
};

export const preloadHeaderResources = () => {
  const brandId = window.__UAA_STATE__?.userContext.brandId;
  const deviceType = emptyState.userContext.deviceType;
  const { name } = PageContextUtils.getClientProviderData();
  const isPC = deviceType.toLocaleLowerCase() === "pc";
  const isSettingsDrawerEnabled = emptyState.settings.isEnabled;

  preloadCrossSellResources(isPC);
  getHeaderLayoutComponent(brandId, name, deviceType).preload();
  HeaderChunk.preload();
  if (isPC) {
    if (!isSettingsDrawerEnabled) {
      preloadOddsFormatItem({ isPC: true, clientProvider: name });
      preloadLanguageHeaderMenuItem(true);
    } else {
      SettingsPC.preload();
    }
    HeaderButtonsPC.preload();
    HeaderLogoReward.preload();
  } else {
    HeaderButtonsMobile.preload();
  }
};
