import { ClientsFramework } from "../external/clients-framework";
import { UCSDK } from "../external";

export default {
  ClientsFrameworkService: {
    Init: { getUserBalanceAsync: ClientsFramework.initGetUserBalanceAsync },
    PostLogin: { InitAsync: ClientsFramework.postLoginInitAsync },
    Registration: {
      openAsync: ClientsFramework.registrationOpenAsync
    },
    OpenClientProductPackage: {
      clientAutoLoginAsync:
        ClientsFramework.openClientProductPackageClientAutoLoginAsync
    },
    Logout: {
      cleanUserStorageItemsAsync: ClientsFramework.cleanUserStorageItemsAsync,
      doLogoutWithoutRefreshAsync:
        ClientsFramework.logoutDoLogoutWithoutRefreshAsync,
      doLogoutAsync: ClientsFramework.logoutDoLogoutAsync,
      doLogoutRefreshStateAsync:
        ClientsFramework.logoutdoLogoutRefreshStateAsync
    }
  },
  UCSDKService: {
    getTopicAsync: UCSDK.getTopicAsync,
    getTopic: UCSDK.getTopic,
    getB2CCookieContainerIdAsync: UCSDK.getB2CCookieContainerIdAsync,
    findIfIsUserEligibleAsync: UCSDK.findIfIsUserEligibleAsync,
    getActiveAbTestFeaturesAsync: UCSDK.getActiveAbTestFeaturesAsync,
    checkEnvAuthPasswordAsync: UCSDK.checkEnvAuthPasswordAsync,
    clearNativeDataStore: UCSDK.clearNativeDataStore,
    updateNativeDataStore: UCSDK.updateNativeDataStore
  }
};
