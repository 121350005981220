export class StoreLoader {
  static createStoreAsync = async () => {
    const { storeInit } = await import(
      /* webpackChunkName: "store-initializer" */
      `./store`
    );

    return storeInit();
  };
}
