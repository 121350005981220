import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MenuActionCreators } from "../../redux/action-creators";
import { MenuItem } from "./MenuItem";
import { MenuSelectors } from "../../redux/selectors";
import PropTypes from "prop-types";
import { ClientProviderEnum } from "../../constants";
import { PageContextUtils } from "../../utils";

const useManualClickSelection = (item, isSportCategory) => {
  const dispatch = useDispatch();
  const selectedItem = useSelector(MenuSelectors.selectedItem);
  const favouriteSports = useSelector(MenuSelectors.favouriteSports);

  const isSelected = useMemo(() => {
    if (
      selectedItem &&
      ((item.sportID &&
        selectedItem.sportID &&
        item.sportID === selectedItem.sportID) ||
        item === selectedItem)
    )
      return true;
    if (
      favouriteSports?.find(
        favItem => favItem === item || favItem.sportID === item?.sportID
      )
    ) {
      return item?.sportID === selectedItem?.sportID;
    }
  }, [item, selectedItem, favouriteSports]);

  const onSelect = useCallback(() => {
    if (!isSelected) dispatch(MenuActionCreators.select(item));
  }, [dispatch, isSelected, item]);

  const isFavourite = useMemo(
    () =>
      isSportCategory
        ? !!favouriteSports.find(fs => fs.sportID === item.sportID)
        : false,
    [item, isSportCategory, favouriteSports]
  );

  return [onSelect, isSelected, isFavourite];
};

export const SelectableMenuItem = props => {
  const {
    item,
    onClick,
    component,
    isSportCategory,
    disableClickStyles,
    dataTestId,
    ...restProps
  } = props;
  const [onSelect, isSelected, isFavourite] = useManualClickSelection(
    item,
    isSportCategory
  );
  const { name } = PageContextUtils.getClientProviderData();

  const onClickHandler = useCallback(
    e => {
      if (typeof onClick === "function" && onClick(e)) {
        return true;
      }
      onSelect(item);
      return true;
    },
    [item, onClick, onSelect]
  );

  if (
    name === ClientProviderEnum.Spectate &&
    isSportCategory &&
    !item.active &&
    !isFavourite
  ) {
    return null;
  }

  const MenuItemComponent = component || MenuItem;
  return (
    <MenuItemComponent
      item={item}
      dataTestId={dataTestId}
      onClick={onClickHandler}
      isActive={disableClickStyles ? undefined : isSelected}
      isFavourite={isFavourite}
      {...restProps}
    />
  );
};

SelectableMenuItem.defaultProps = {
  hasIcon: true
};

SelectableMenuItem.propTypes = {
  item: PropTypes.object,
  component: PropTypes.node,
  onClick: PropTypes.func,
  isSportCategory: PropTypes.bool,
  disableClickStyles: PropTypes.bool,
  dataTestId: PropTypes.string.isRequired
};
