import { UCSDKLibrary } from "library-manager";

export class UCSDkService {
  getTopic = (channelName, topicName) => {
    const topic = global.uc?.channels[channelName]?.topics[topicName];

    return topic;
  };

  getTopicAsync = async (channelName, topicName) => {
    await UCSDKLibrary;
    const topic = global.uc?.channels[channelName]?.topics[topicName];

    return topic;
  };

  getB2CCookieContainerIdAsync = async () => {
    await UCSDKLibrary;

    const cookieBannerContainerId = global.uc.b2c?.getCookieContainer();

    return cookieBannerContainerId;
  };

  findIfIsUserEligibleAsync = async featureName => {
    await UCSDKLibrary;

    const isUserEligible = await global.uc.feature?.isUserEligible(featureName);

    return isUserEligible;
  };

  getActiveAbTestFeaturesAsync = async featureTestId => {
    await UCSDKLibrary;

    const abTestActiveFeatures =
      await global.uc.feature?.getActiveAbTestFeatures(featureTestId);

    return abTestActiveFeatures;
  };

  checkEnvAuthPasswordAsync = async password => {
    await UCSDKLibrary;
    const result =
      await global.uc.envAccessAuthFunctions?.checkPassword(password);
    return result;
  };

  clearNativeDataStore = async () => {
    await UCSDKLibrary;

    global.NativeInterface?.clearNativeDataStore();
  };

  updateNativeDataStore = async () => {
    await UCSDKLibrary;

    global.NativeInterface?.updateNativeDataStore();
  };
}
