import { PageContextUtils } from "../utils/pageContextUtils";

export class AppFeatureUtils {
  static findIfIsInFeature = appFeature => {
    const { features } = PageContextUtils.getFeaturesData();

    const isInFeature = features
      ?.filter(feature => feature.value.toLowerCase() === "on")
      .some(activeFeature => activeFeature.id === appFeature);

    return isInFeature;
  };
}
