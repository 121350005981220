export const loadRewardResources = async (brandId, clientProvider) => {
  try {
    return import(
      /* webpackChunkName: "[request]" */

      `../Brands/${brandId}/${clientProvider}/Rewards.resource`
    );
  } catch {
    return import(
      /* webpackChunkName: "[request]" */ `../Brands/default/${clientProvider}/Rewards.resource`
    );
  }
};

export const RewardsResource = { promise: null };

export const preloadResources = async ({ clientProvider, brandId }) => {
  const rewardsResources = await loadRewardResources(brandId, clientProvider);
  RewardsResource.promise = Promise.resolve(rewardsResources);
  return RewardsResource.promise;
};
