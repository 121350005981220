export const ControlCenterComponentType = {
  HeaderButton: 1,
  Mini: 2
};

export const DrawerActionType = {
  UserArea: 1,
  External: 2,
  OpenControlCenterFull: 3
};

export const CloseControlCenterSource = {
  MiniSafeZone: "MiniSafeZone",
  UserArea: "userArea",
  ClientPage: "clientPage"
};

export const OpenControlCenterSource = {
  Lobby: "lobby",
  UserArea: "mini",
  Rg_policy: "rg_policy",
  Cashier: "cashier",
  Membership: "membership",
  Pending_action: "pending_action",
  Boss: "boss",
  Email: "email",
  Promotion: "promotion"
};

export const ControlCenterInitiatorSource = {
  UserAreaMobileHamburgerMenu: "userAreaMobileHamburgerMenu",
  UserAreaMobileUserMenu: "userAreaMobileUserMenu"
};

export const ScriptState = {
  Initial: 0,
  Loaded: 1,
  Failure: 2
};

//https://confluence/display/RG/Tracking
export const ControlCenterEvents = {
  FULL_CLOSE_EXTERNAL: "sz_full_close_external", //01020104
  FULL_OPEN: "sz_full_open", //01020101
  FULL_READY: "sz_full_ready", //01020102
  MINI_CLOSE_INTERNAL: "sz_mini_close_internal", //01010103
  MINI_CLOSE_EXTERNAL: "sz_mini_close_external", //01010104
  MINI_OPEN: "sz_mini_open", //01010101
  MINI_READY: "sz_mini_ready" //01010102
};

export const ControlCenterEventStatus = {
  Failure: 0,
  Success: 1,
  Partial: 2
};

export const ControlCenterTimeoutErrorCode = "10";
