import React from "react";
import PropTypes from "prop-types";
import {
  ControlCenterHeaderButtonLoadable,
  ControlCenterMiniLoadable
} from "../components";
import { ControlCenterComponentType } from "../enums";
import { useSelector } from "react-redux";
import { ControlCenterSelectors } from "../../../redux/selectors";

export const ControlCenterFactory = ({ componentType, ...restProps }) => {
  const enabled = useSelector(ControlCenterSelectors.enabled);

  if (!enabled) return false;
  if (!componentType) return null;

  switch (componentType) {
    case ControlCenterComponentType.HeaderButton: {
      return <ControlCenterHeaderButtonLoadable {...restProps} />;
    }
    case ControlCenterComponentType.Mini: {
      return <ControlCenterMiniLoadable {...restProps} />;
    }
  }
};

ControlCenterFactory.propTypes = {
  componentType: PropTypes.number
};
