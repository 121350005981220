import React from "react";
import PropTypes from "prop-types";

export const ImageIcon = props => {
  const { path, iconName, dataTestId, classNames, children, ...restProps } =
    props;

  return (
    <div className="svg-wrapper">
      <img
        {...restProps}
        id={iconName}
        src={path}
        className={classNames}
        data-testid={dataTestId}
        alt={dataTestId}
      />
      {children}
    </div>
  );
};

ImageIcon.propTypes = {
  iconName: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  children: PropTypes.any,
  path: PropTypes.string,
  classNames: PropTypes.string
};
